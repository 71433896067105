import React from 'react'
import {
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Link,
  Container,
} from '@mui/material'
import PageContainer from 'components/Shared/PageContainer'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const people = {
  utn: [
    { name: 'Dr. Javier Iparraguirre', email: 'jiparraguirre@frbb.utn.edu.ar' },
    { name: 'Ing. Elec. Leandro Balmaceda', email: '' },
    { name: 'Ariel I. Díaz', email: '' },
    { name: 'Santiago L. Aggio', email: '' },
    { name: 'Adrián Rostagno', email: '' },
    { name: 'Tomas Crisol', email: '' },
    { name: 'Joel Ermantraut', email: '' },
  ],
  uns: [
    {
      name: 'Dr. Guillermo R. Chantre (Profesor Asociado DA-UNS, Investigador adjunto CONICET)',
      email: 'gchantre@criba.edu.ar',
    },
    {
      name: 'Ing. Agr. Franco A. Molinari (Becario Doctoral CONICET)',
      email: '',
    },
    { name: 'Ian Didriksen (Becario de grado PGI-TIR)', email: '' },
  ],
  palpiqui: [
    {
      name: 'Dr. Aníbal M. Blanco (Investigador Independiente CONICET)',
      email: 'ablanco@plapiqui.edu.ar',
    },
    { name: 'Ing. Qca (MSc) Lucía Damiani', email: '' },
  ],
  collaborators: [
    {
      name: 'Ing. Agr. (MSc) Mario Vigna (INTA EEA BORDENAVE, BUENOS AIRES)',
      email: '',
    },
    {
      name: 'Ing. Agr. (Dr.) Juan P. Renzi (INTA EEA HILARIO ASCASUBI, BUENOS AIRES)',
      email: '',
    },
    {
      name: 'Ing. Agr. (Dr.) Jorgelina C. Montoya (INTA EEA ANGUIL, LA PAMPA)',
      email: '',
    },
  ],
}

const institutions = [
  {
    name: 'Bolsa de Cereales y Productos de Bahía Blanca',
    url: 'https://www.bcp.org.ar/index.asp',
  },
  { name: 'MeteoBahía', url: 'https://www.meteobahia.com.ar' },
  {
    name: 'Instituto Nacional de Investigación Agropecuaria  (INTA)',
    url: 'https://www.argentina.gob.ar/inta',
  },
]

const AboutUs = () => {
  const styles = {
    heroContent: {
      pt: 8,
      pr: 6
    },
    subtitle: {
      color: '#333',
      marginTop: 40,
      marginLeft: 50,
      marginRight: 50,
    },
    subtitle2: {
      marginTop: 30,
      marginBottom: 15,
      color: '#444',
      marginLeft: 50,
      marginRight: 50,
    },
    text: {
      color: '#555',
      marginBottom: 20,
      marginLeft: 50,
      marginRight: 50,
    },
    listText: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    url: {
      textDecoration: 'none',
      color: '#555',
    },
  }

  return (
    <PageContainer>
      <div sx={styles.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Quienes somos
          </Typography>
        </Container>
      </div>

      <Typography sx={styles.text}>
        La agricultura es uno de los pilares fundamentales de la economía
        nacional. Si bien en los últimos años, el sector agropecuario argentino
        se ha tecnificado notablemente, aún no ha incorporado de manera amplia y
        sistemática el uso de herramientas computacionales de asistencia a la
        toma de decisiones para el manejo de malezas. Entendemos que disponer de
        dichas herramientas contribuiría a realizar una mejor planificación de
        los sistemas de cultivo incluyendo variables tanto económicas como
        ambientales. Sin embargo, en la actualidad no abundan herramientas
        amigables y de fácil acceso que permitan hacer un uso práctico del
        conocimiento disponible sobre dinámica poblacional de malezas y su
        aplicación a situaciones reales de escenarios productivos.{' '}
      </Typography>

      <Typography sx={styles.text}>
        En la práctica de campo, los extensionistas y asesores técnicos utilizan
        principalmente el conocimiento empírico resultante de la experiencia
        individual y grupal apoyándose eventualmente en el uso de planillas de
        cálculo que funcionan principalmente como “base de datos” para registrar
        las principales variables de interés agronómico. Por otra parte, si bien
        existen estudios agronómicos sobre muchas especies de malezas de
        distintas regiones del país, el mismo se encuentra en muchos casos en
        tesis, artículos de congreso o publicaciones en inglés y por ende no
        disponible para la comunidad en forma de herramientas de uso práctico.
      </Typography>

      <Typography sx={styles.text}>
        El desarrollo de sistemas computacionales de soporte a la toma de
        decisiones basados en modelos matemáticos requiere de cierto dominio de
        plataformas de programación, diseño de interfaces de usuario adecuadas,
        su vinculación con pronósticos de datos meteorológicos y el
        mantenimiento operativo de los sistemas a largo plazo, tratándose de una
        actividad eminentemente interdisciplinaria. Estas barreras hacen que, en
        la práctica, muy poca información esté accesible para un uso cotidiano,
        a pesar de la gran cantidad de datos y herramientas potencialmente
        valiosas de acceso público.{' '}
      </Typography>

      <Typography sx={styles.text}>
        Con el objetivo de recopilar información útil disponible en el sector
        académico y hacerla visible a una potencial comunidad adoptante
        (extensionistas, asesores y productores agropecuarios) iniciamos en 2018
        un proyecto interdisciplinario tendiente a concentrar ese conocimiento
        en la forma de herramientas de apoyo a la toma de decisiones
        agronómicas. Con este propósito se encuentra en construcción este sitio
        web para publicar de una manera amigable la información proporcionada
        por distintos modelos para asistencia al manejo de malezas desarrolladas
        en el ámbito de nuestro grupo de investigación. En esta plataforma se
        pueden visualizar los resultados de la ejecución automática de una serie
        de modelos, los cuales están vinculados con sistemas de predicción
        meteorológica.
      </Typography>

      <Typography sx={styles.text}>
        Por el momento esta plataforma está orientada a presentar solamente
        algunos desarrollos resultantes de las actividades de investigación
        realizadas por nuestro grupo, las cuales poseen en la actualidad un
        limitado nivel de validación. Se espera que con el tiempo estas
        herramientas puedan ir validándose con la retroalimentación recibida de
        nuestra audiencia objetivo. Asimismo, nos gustaría incorporar los
        desarrollos de otros grupos de investigación que deseen aportar a esta
        iniciativa. El sistema posee una estructura modular y está previsto que
        vaya incrementando sus prestaciones con distintas herramientas
        complementarias en el futuro.{' '}
      </Typography>

      <hr style={{ width: '90%', marginBottom: 0, marginTop: 30 }} />

      <Typography variant="h4" sx={styles.subtitle}>
        Equipo de Desarrollo
      </Typography>

      <Typography sx={styles.subtitle2} variant="h5">
        Universidad Tecnológica Nacional, Bahía Blanca (UTN, FRBB)
      </Typography>
      <List sx={styles.listText} dense={true}>
        {people.utn.map(person => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={person.name}
              secondary={person.email !== '' ? person.email : null}
            />
          </ListItem>
        ))}
      </List>

      <Typography sx={styles.subtitle2} variant="h5">
        Departamento de Agronomía/CERZOS (UNS-CONICET)
      </Typography>
      <List sx={styles.listText} dense={true}>
        {people.uns.map(person => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={person.name}
              secondary={person.email !== '' ? person.email : null}
            />
          </ListItem>
        ))}
      </List>

      <Typography sx={styles.subtitle2} variant="h5">
        PLAPIQUI (UNS-CONICET)
      </Typography>
      <List sx={styles.listText} dense={true}>
        {people.palpiqui.map(person => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={person.name}
              secondary={person.email !== '' ? person.email : null}
            />
          </ListItem>
        ))}
      </List>

      <Typography sx={styles.subtitle2} variant="h5">
        Investigadores colaboradores
      </Typography>
      <List sx={styles.listText} dense={true}>
        {people.collaborators.map(person => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={person.name}
              secondary={person.email !== '' ? person.email : null}
            />
          </ListItem>
        ))}
      </List>

      <Typography sx={styles.subtitle2} variant="h5">
        Instituciones que aportan información{' '}
      </Typography>
      <List sx={styles.listText} dense={true}>
        {institutions.map(inst => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={inst.name}
              secondary={
                <Link className={styles.url} href={inst.url}>
                  {inst.url}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>

      <hr style={{ width: '90%', marginBottom: 0, marginTop: 30 }} />

      <Typography variant="h4" sx={styles.subtitle}>
        Fuentes de financiación
      </Typography>

      <Typography sx={styles.subtitle2} variant="h5">
        Universidad Tecnológica Nacional
      </Typography>

      <Typography sx={styles.text}>
        Proyecto de Desarrollo Tecnológico y Social Orientado (PDTSO) de la
        Universidad Tecnológica Nacional (Facultad Regional Bahía Blanca),
        titulado: “Plataforma web para asistencia a la toma de decisiones en el
        manejo integrado de malezas” (Director: Dr. Javier Iparraguirre).
      </Typography>

      <Typography sx={styles.subtitle2} variant="h5">
        Universidad Nacional del Sur
      </Typography>

      <Typography sx={styles.text}>
        Proyecto de Grupo de Investigación en Temas de Interés Regional de la
        UNS (PGI-TIR 80020190100001SU), titulado: "Herramientas de asistencia a
        la toma de decisiones para el manejo sustentable de malezas en sistemas
        agrícolas del sur bonaerense" (Director: Dr. Guillermo R. Chantre).
      </Typography>

      <Typography sx={styles.subtitle2} variant="h5">
        Agencia MINCYT
      </Typography>

      <Typography sx={styles.text} style={{ marginBottom: 70 }}>
        PICT-2016-1575, titulado: “Desarrollo de herramientas de asistencia a la
        toma de decisiones basadas en el manejo integrado de malezas: su
        aplicación a sistemas agrícolas del centro-sur bonaerense” (Director:
        Dr. Guillermo R. Chantre).
      </Typography>
    </PageContainer>
  )
}

export default AboutUs
