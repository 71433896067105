import React from 'react'
import { Button } from '@mui/material'
import { jsonToCSV, jsonToCSVSTM2 } from 'utils/jsonCsvConverter'

const exportFile = (data, filename, contentType) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(data))], {
      type: contentType,
    })
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    var a = document.createElement('a')
    a.download = filename
    a.href = 'data:' + contentType + ',' + encodeURIComponent(data)
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

const DownloableButton = ({ legend, data, isJson, filename = 'export' }) => {
  const exportFunction = () => {
    exportFile(
      isJson
        ? JSON.stringify(data)
        : data.length === 4 // Si el length es igual a 4, entonces se trata de datos del sistema STM2
        ? jsonToCSVSTM2(data)
        : jsonToCSV(data),
      `${filename}${isJson ? '.json' : '.csv'}`,
      `application/${isJson ? 'json' : 'csv'};charset=utf-8;`,
      isJson,
    )
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => exportFunction()}
    >
      {legend}
    </Button>
  )
}

export default DownloableButton
