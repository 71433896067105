import axios from 'axios'

const BASE_URL = 'http://pronostico-malezas.frbb.utn.edu.ar/weatherAPI/meteo'

export const locations = {
  'meteo bordenave': {
    id: 4,
    name: 'Bordenave (Buenos Aires) – EEA INTA Bordenave (37.75, -63.08)',
  },
  'meteo anguil': {
    id: 1,
    name: 'Anguil (La Pampa) - EEA INTA Anguil  (-36.54, -63.99)',
  },
  'meteo bahia blanca': {
    id: 7,
    name: 'Bahía Blanca (Buenos Aires) – Depto. Agronomía UNS (-38.696, -62.249)',
  },
  'meteo tres arroyos': {
    id: 6,
    name: 'Tres Arroyos (Buenos Aires) – CEI INTA Barrow (-38.32, -60.25)',
  },
  // 'la esperanza': {
  //   id: 3,
  //   name: 'La Esperanza (La Pampa) (-36.54, -64.08)',
  // },
  // 'santa maria': {
  //   id: 2,
  //   name: 'Santa María (La Pampa) (-36.57, -64.15)',
  // },
  'meteo ascasubi': {
    id: 5,
    name: 'Hilario Ascasubi (Buenos Aires) – EEA INTA Ascasubi (-39.396, -62.626)',
  },
  'meteo gral pico': {
    id: 10,
    name: 'General Pico (La Pampa) - (-35.659, -63.767)',
  },
}

export const getLocationsMeteo = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/locations`)
    let ret_locations = response.data.map(location => ({
      id: location.name,
      name: locations[location.name].name,
    }))
    return ret_locations
  } catch (err) {
    console.error('Error getting locations data:', err)
    throw new Error('Error getting locations data')
  }
}

export const getWeatherMeteo = async location => {
  try {
    const data = await axios.get(`${BASE_URL}/?location=${location}`)
    // Fix data forecast and weather in reverse order
    data.data.forecast = data.data.forecast.reverse()
    return data
  } catch (err) {
    console.error('Error getting weather data:', err)
    throw new Error('Error getting weather data:')
  }
}
