import React from 'react'
import Typography from '@mui/material/Typography'

const ComoFuncionaCultivoMaleza = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom style={{ marginTop: '1em' }}>
        ¿Cómo funciona?
      </Typography>
      <Typography variant="body1">
        La adecuada predicción de la <b>interacción entre las malezas y los cultivos</b> es uno de
        los requerimientos esenciales para un manejo integrado efectivo. Este sistema permite
        predecir dicha interacción, y estimar el impacto de diferentes <b>acciones de control</b>{' '}
        basado en el modelo de simulación propuesto en [1] y utilizando predicciones meteorológicas
        de la región. Específicamente se emplean pronósticos de los siguientes datos del estado del
        tiempo:
      </Typography>
      <Typography variant="body1" component="ul" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <li>Temperatura mínima diaria</li>
        <li>Temperatura máxima diaria</li>
        <li>Precipitación diaria</li>
      </Typography>
      <Typography variant="body1">
        Sobre la base de estas predicciones se estima la <b>emergencia futura</b> de la maleza y su
        desarrollo en términos de sus <b>diferentes estados fenológicos</b> (densidad acumulada),
        así como el <b>desarrollo del cultivo</b> (TPLA). El sistema implementa una mecánica de
        horizonte móvil:
      </Typography>
      <Typography variant="body1" component="ol" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <li>
          Entre el 1ro de enero y el día de ayer, se emplean datos de registros meteorológicos.
        </li>
        <li>
          Entre el día de hoy y el 31 de diciembre, la emergencia se calcula empleando el pronóstico
          del estado del tiempo.
        </li>
      </Typography>
      <Typography variant="body1">
        Con fines demostrativos se presenta una simulación correspondiente a un cultivo de trigo en
        competencia con Avefa fatua y empleando dos aplicaciones de herbicidas pre emergentes de
        acuerdo al siguiente cronograma de manejo:
      </Typography>
      <Typography variant="body1" component="ol" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <li>Fecha aplicación 1 herbicida: 1/4/2020</li>
        <li>Fecha aplicación 2 herbicida: 30/6/2020</li>
        <li>Fecha de siembra: 1/7/2020</li>
      </Typography>
      <Typography variant="h6" gutterBottom>
        Referencias
      </Typography>
      <Typography variant="body1">
        1. F. A. Molinari, A. M. Blanco, M. R. Vigna, G. R. Chantre (2020); Towards an Integrated
        Weed Management Decision Support System: a Simulation Model for Weed-Crop Competition and
        Control. Computers and Electronics in Agriculture
      </Typography>
    </>
  )
}

export default ComoFuncionaCultivoMaleza
