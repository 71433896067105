const emergencia = [
  { media: 0.81, max: 0.88, min: 0.72 },
  { media: 0.11, max: 0.17, min: 0.06 },
  { media: 0.0199999999999999, max: 0.04, min: 0.01 },
  { media: 0.01, max: 0.01, min: 0.01 },
]

/**
 * Método que retorna la emergencia de acuerdo a los parámetros seteados
 * del modelo, en caso de requerirse una emergencia que no se encuentra,
 * retorna -1.
 * @param {int} number
 * @returns int
 */
export const getEmerge = number => {
  if (number - 1 > emergencia.length || number - 1 < 0) return -1
  return emergencia[number - 1].media
}

const supervivencia = [
  { media: 0.153 },
  { media: 0.79, max: 0.91, min: 0.69 },
  { media: 0.0185669381967253 },
  { media: 0.03833, max: 0.05, min: 0.03 },
]

/**
 * Método que retorna la supervivencia de acuerdo a los parámetros seteados
 * del modelo, en caso de requerirse una supervivencia que no se encuentra,
 * retorna -1.
 * @param {int} number
 * @returns int
 */
export const getSurvival = number => {
  if (number - 1 > emergencia.length || number - 1 < 0) return -1
  return supervivencia[number - 1].media
}
