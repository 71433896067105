import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

import Maleza from '../Shared/Maleza'

const classes = {
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
    paddingTop: 0,
  },
}

const CampoCard = ({
  nombreCampo,
  nombreLote,
  localidad,
  latLng,
  malezas,
  onCargaDatosClicked,
}) => {

  return (
    <Card sx={classes.card}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={nombreCampo}
        subheader={nombreLote}
        subheaderTypographyProps={{ variant: 'h6' }}
      />
      <CardContent sx={classes.cardContent}>
        <Typography variant="body1">{localidad}</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {latLng}
        </Typography>
        {malezas.map(({ id, nombre }) => (
          <Maleza key={id} nombre={nombre} />
        ))}
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={onCargaDatosClicked}>
          Cargar datos
        </Button>
      </CardActions>
    </Card>
  )
}

CampoCard.propTypes = {
  nombreCampo: PropTypes.string.isRequired,
  nombreLote: PropTypes.string.isRequired,
  localidad: PropTypes.string.isRequired,
  latLng: PropTypes.string.isRequired,
  malezas: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, nombre: PropTypes.string }),
  ).isRequired,
  onCargaDatosClicked: PropTypes.func.isRequired,
}

export default CampoCard
