import React from 'react'

import 'chartjs-plugin-annotation'
import blue from '@mui/material/colors/blue'
import red from '@mui/material/colors/red'

import { defaultLinesStyle } from '../../utils/chartsConfig'
import BarChart from 'components/Shared/BarChart'

const ResiembraViciaChart = ({ data }) => {
  const blueColor = blue[500]
  const redColor = red[500]

  const labels = data.map(entry => `Año ${entry.anio}`)
  const cereal = data.map(entry => entry.faseCereal)
  const pastura = data.map(entry => entry.fasePastura)

  const chartData = {
    labels,
    datasets: [
      {
        ...defaultLinesStyle(blueColor),
        label: 'Fase Pastura',
        data: pastura,
        yAxisID: 'fase',
      },
      {
        ...defaultLinesStyle(redColor),
        label: 'Fase Cereal',
        data: cereal,
        yAxisID: 'fase',
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'fase',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Plantas de Vicia villosa por m²',
          },
          labels: {
            show: true,
          },
        },
      ],
    },
  }

  return <BarChart data={chartData} showLegends={true} options={options} />
}

export default ResiembraViciaChart
