import React from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { Button, Typography } from '@mui/material'
import Link from '@mui/material/Link'

const classes ={
  buttonText: {
    textTransform: 'none',
  },
}

const UserMenu = ({ showMenu, onLogoutClicked }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const closeUserMenu = () => setAnchorEl(null)

  const handleLogout = () => {
    onLogoutClicked()
    closeUserMenu()
  }

  return showMenu ? (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeUserMenu}
      >
        <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  ) : (
    <>
      <Button
        component={Link}
        href={'/users/login'}
        color="inherit"
        underline="none"
        sx={classes.buttonText}
      >
        <Typography color="inherit">Ingreso</Typography>
      </Button>
      <Button
        component={Link}
        href={'/users/signup'}
        color="inherit"
        underline="none"
        sx={classes.buttonText}
      >
        <Typography color="inherit">Registro</Typography>
      </Button>
    </>
  )
}

export default UserMenu
