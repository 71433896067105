import React, { useCallback, useEffect, useState } from 'react'

import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Link from '@mui/material/Link'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'

import Chart from 'components/Weather/ChartWeather'
import PageContainer from 'components/Shared/PageContainer'
import '../PronosticoMalezas/PronosticoMalezas.css'

import DownloableButton from 'components/Shared/DownloableButton'
import { Grid } from '@mui/material'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

// const graphClasses = theme => ({
//   graph: {
//     backgroundColor: theme.palette.background.paper,
//     marginBottom: 20,
//   },
// })

const WeatherComponent = props => {
  const [error, setError] = useState(null)
  const [selectedGraph, setSelectedGraph] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState()
  const [chartWeatherData, setChartWeatherData] = useState({
    labels: [],
    temp: [],
    tmax: [],
    tmin: [],
    rain: [],
    humidity: [],
  })
  const [chartForecastData, setChartForecastData] = useState({
    labels: [],
    temp: [],
    tmax: [],
    tmin: [],
    rain: [],
    humidity: [],
  })
  const [locations, setLocations] = useState([])
  const [weatherJsonData, setWeatherJsonData] = useState({})
  const [forecastJSonData, setForecastJsonData] = useState({})

  // const classes = useStyles(customStyles)

  const processServerResponse = serverResponse => {
    let labels = []
    let temp = []
    let tmax = []
    let tmin = []
    let rain = []
    let humidity = []

    serverResponse.forEach(entry => {
      labels.unshift(entry.day)
      temp.unshift(entry.temp)
      tmax.unshift(entry.temp_max)
      tmin.unshift(entry.temp_min)
      rain.unshift(entry.rain)
      humidity.unshift(entry.humidity)
    })

    return { labels, temp, tmax, tmin, rain, humidity }
  }

  const getWeatherData = useCallback(
    async location => {
      props
        .getWeather(location)
        .then(resWeather => {
          let weatherData = resWeather.data
          setChartWeatherData(processServerResponse(weatherData.weather))
          setChartForecastData(processServerResponse(weatherData.forecast))
          setWeatherJsonData(weatherData.weather)
          setForecastJsonData(weatherData.forecast)
        })
        .catch(err => {
          console.log('Error getting weather data')
          setError(err)
        })
    },
    [props],
  )

  const getGraphData = useCallback(
    async location => {
      // If it takes more than 1 sec to load, show a spinner.
      const timeout = setTimeout(() => setLoading(true), 1000)
      if (location) getWeatherData(location)
      else
        props
          .getLocations()
          .then(locations => {
            setSelectedLocation(locations[0].id)
            setLocations(locations)
            getWeatherData(locations[0].id)
          })
          .catch(e => setError(e))

      clearTimeout(timeout)
      setLoading(false)
    },
    [getWeatherData, props],
  )

  useEffect(() => {
    getGraphData()
  }, [getGraphData])

  const handleChange = (event, value) => {
    setSelectedGraph(value)
  }

  const changeSelectedLocation = event => {
    const newSelectedLocation = event.target.value
    setSelectedLocation(newSelectedLocation)
    getGraphData(newSelectedLocation)
  }

  const LoadingOverlay = () => (
    <div className="Overlay">
      <CircularProgress />
    </div>
  )

  const weatherChart = error ? (
    <Typography>Error al obtener datos del clima</Typography>
  ) : (
    <Chart
      labels={chartWeatherData.labels}
      temp={chartWeatherData.temp}
      tmax={chartWeatherData.tmax}
      tmin={chartWeatherData.tmin}
      rain={chartWeatherData.rain}
      humidity={chartWeatherData.humidity}
    />
  )

  const forecastChart = error ? (
    <Typography>Error al obtener los datos del pronóstico</Typography>
  ) : (
    <Chart
      labels={chartForecastData.labels}
      tmax={chartForecastData.tmax}
      tmin={chartForecastData.tmin}
      rain={chartForecastData.rain}
      humidity={chartForecastData.humidity}
      temp={chartForecastData.temp}
    />
  )

  return (
    <PageContainer>
      <Typography variant="h5" gutterBottom component="h2">
        Registro histórico y pronóstico meteorológico
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Localidad
      </Typography>
      <div style={{ marginBottom: 20, maxWidth: 600 }}>
        <Select
          value={selectedLocation ?? ''}
          onChange={changeSelectedLocation}
        >
          {locations.map(location => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <Typography variant="h6" gutterBottom>
        {selectedGraph === 0
          ? 'Registros climaticos históricos'
          : 'Pronostico de los próximos siete días'}
      </Typography>
      <div sx={{ backgroundColor: theme.palette.background.paper, marginBottom: 20 }}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedGraph}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Registro anual" />
            <Tab label="Pronóstico 7 días" />
          </Tabs>
        </AppBar>
        {selectedGraph === 0 && (
          <TabContainer>
            <div className="Card">
              <div className="Chart">
                {weatherChart}
                {props.descriptionWeather}
              </div>
            </div>
          </TabContainer>
        )}
        {selectedGraph === 1 && (
          <TabContainer>
            <div className="Card">
              <div className="Chart">
                {forecastChart}
                {props.descriptionForecast}
              </div>
            </div>
          </TabContainer>
        )}
      </div>

      <Grid container style={{ marginTop: 20 }} justify="center">
        <Grid item xs={3} sm={3}>
          <DownloableButton
            legend={'Descargar clima'}
            data={weatherJsonData}
            isJson={false}
            filename={'weather'}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <DownloableButton
            legend={'Descargar pronóstico'}
            data={forecastJSonData}
            isJson={false}
            filename={'forecast'}
          />
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: 50 }}>
        Contacto
      </Typography>
      <Typography variant="body1">
        Para contactarse con nosotros, visite nuestro sitio web{' '}
        <Link
          href="https://bhi-research.github.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          bhi-research.github.io
        </Link>
        .
      </Typography>
      {loading && <LoadingOverlay />}
    </PageContainer>
  )
}

export default WeatherComponent
