import { useMutation } from 'react-query'
import { nuevoCampo } from 'utils/server/server'

export const useAgregarNuevoCampo = () => {
  const [mutate, { isLoading, error, reset }] = useMutation(nuevoCampo)

  const agregarNuevoCampo = async (
    nombreCampo,
    nombreLotes,
    malezas,
    provincia,
    localidad,
    latitudLongitud,
  ) => {
    await mutate({
      nombreLotes,
      nombreCampo,
      malezas,
      provincia,
      localidad,
      latitudLongitud,
    })
  }

  return { agregarNuevoCampo, isLoading, error, reset }
}
