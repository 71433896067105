import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

const LoadingOverlay = () => {
  const classes = {
    Overlay: {
      height: '100vh',
      width: '100vw',
      backgroundColor: 'rgba(256, 256, 256, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
    }
  }

  return (
    <div sx={classes.Overlay}>
      <CircularProgress />
    </div>
  )
}

export default LoadingOverlay
