import { Link, Typography } from '@mui/material'
import React from 'react'

const ResiembraText = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom style={{ marginTop: 40 }}>
        Modelo demográfico de vicia villosa
      </Typography>
      <Typography variant="body1">
        La Vicia villosa constituye una leguminosa forrajera anual clave en los
        sistemas agropecuarios de zonas semiáridas. Debido a su alta capacidad
        de resiembra natural, pueden lograrse pasturas productivas sin necesidad
        de realizar una nueva siembra. Para promover su uso agropecuario debe
        conseguirse un adecuado ajuste del período de emergencia y una
        conveniente densidad de plantas. Un efecto adverso en la rotación de
        cultivos puede ocurrir por la aparición de plantas “voluntarias” de
        Vicia villosa, también llamadas “guachas”, en cultivos sucesivos de
        cereales, como trigo, cebada, avena, etc.
      </Typography>

      <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
        ¿Cómo funciona?
      </Typography>
      <Typography variant="body1">
        Este modelo demográfico permite{' '}
        <b>simular el stand de plantas de Vicia villosa</b> agronómicamente
        productivo (> 30 plantas m-2) o como maleza en cultivos sucesivos
        (voluntarias),{' '}
        <b>
          luego de un primer ciclo de Vicia villosa destinado a la producción de
          semillas
        </b>
        . Las variables de entrada para la simulación del modelo consisten en:
      </Typography>

      <Typography
        variant="body1"
        component="dl"
        style={{ marginTop: '1em', marginBottom: '1em', marginLeft: 30 }}
      >
        <li>
          El rendimiento de semillas de vicia en el inicio de la simulación.
        </li>
        <li>
          El nivel de pérdidas de semillas en función del tipo de cosechadora.
        </li>
        <li>La secuencia de cultivos prevista en la rotación.</li>
      </Typography>
      <Typography variant="body1">
        En el caso de la secuencia de cultivos de cereales, con control total o
        parcial de la Vicia villosa voluntaria mediante la utilización de
        herbicidas, y en la fase pastura, con tres niveles de aprovechamiento,
        henificación, pastoreo intenso y racional. <b>La salida del modelo</b>{' '}
        de simulación{' '}
        <b>muestra el stand de plantas de Vicia villosa para la fase pastura</b>{' '}
        (febrero a abril) <b>y en la fase cereal</b> (junio a septiembre){' '}
        <b>durante 4 ciclos consecutivos</b>. El nuevo destino del cultivo de
        Vicia villosa para producción de semilla regresa la simulación al primer
        ciclo.
      </Typography>
      <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
        Referencias
      </Typography>
      <Typography variant="body1">
        [ 1 ] Renzi, J.P., Chantre, G., González-Andújar, J.L., Cantamutto, M.A.
        2019. Development and validation of a simulation model for hairy vetch
        (Vicia villosa Roth) self-regeneration under different crop rotations.
        Field Crops Research 235, 79-86. doi: 10.1016/j.fcr.2019.02.020. -{' '}
        <Link
          href="https://www.sciencedirect.com/science/article/abs/pii/S0378429017318488"
          target="_blank"
          rel="noopener noreferrer"
        >
          consultar paper
        </Link>
      </Typography>
      <Typography variant="body1" style={{ marginTop: 10 }}>
        [ 2 ] Quintana, M., Reinoso, O., Renzi, J.P. 2020. Efecto del momento de
        aprovechamiento forrajero sobre el banco de semillas de Vicia villosa
        Roth, ISSN 0326-0550. 40:90. 43° Congreso Argentino de Producción
        Animal. On-line del 25 al 27 de noviembre. .{' '}
        <Link
          href="https://inta.gob.ar/documentos/efecto-del-momento-de-aprovechamiento-forrajero-sobre-el-banco-de-semillas-de-vicia-villosa-roth"
          target="_blank"
          rel="noopener noreferrer"
        >
          consultar poster
        </Link>
      </Typography>
    </>
  )
}
export default ResiembraText
