export const jsonToCSV = json => {
  const replacer = (key, value) => (value === null ? '' : value)
  const header = Object.keys(json[0])
  const csv = [
    header.join(','),
    ...json.map(row =>
      header
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(','),
    ),
  ].join('\r\n')
  return csv
}

export const jsonToCSVSTM2 = json => {
  const replacer = (key, value) => (value === null ? '' : value)
  let header =
    'datos 1cm,dia,temp,smp,datos 2cm,dia,temp,smp,datos 4cm,dia,temp,smp,datos 10cm,dia,temp,smp'
  let data = []
  let datos = [
    json.find(depth => depth.profundidad === '1cm').datos.reverse(),
    json.find(depth => depth.profundidad === '2cm').datos.reverse(),
    json.find(depth => depth.profundidad === '4cm').datos.reverse(),
    json.find(depth => depth.profundidad === '10cm').datos.reverse(),
  ]
  for (let i = 0; i < datos[0].length; i++) {
    let strToAdd = []
    datos.forEach(row => {
      strToAdd.push('')
      strToAdd.push(JSON.stringify(row[i]['dia'], replacer))
      strToAdd.push(JSON.stringify(row[i]['temp'], replacer))
      strToAdd.push(JSON.stringify(row[i]['smp'], replacer))
    })
    data.push(strToAdd.join(',') + '\r\n')
  }
  return [header, data.join('')].join('\r\n')
}
