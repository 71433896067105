import React, { useRef } from 'react'

import Fab from '@mui/material/Fab'
import MyLocationIcon from '@mui/icons-material/MyLocation'


import { MapContainer, TileLayer, Marker } from 'react-leaflet'

const classes = {
  mapWrapper: {
    height: '100%',
    '&>.leaflet-container': {
      height: '100%',
      width: '100%',
    },
  },
  locationIcon: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    zIndex: 10000,
  },
}

const ZOOM = 13

const Mapa = ({ position, setPosition }) => {
  const markerRef = useRef()
  

  const updatePosition = () => {
    const pos = markerRef.current.leafletElement.getLatLng()
    setPosition(pos.lat, pos.lng)
  }

  const getPosition = () => {
    const geoSuccess = e => {
      const lat = e.coords.latitude
      const lng = e.coords.longitude
      setPosition(lat, lng)
    }

    navigator.geolocation.getCurrentPosition(geoSuccess)
  }

  return (
    <>
      <div sx={classes.mapWrapper}>
        <MapContainer center={position} zoom={ZOOM}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={position}
            draggable
            onDragend={updatePosition}
            ref={markerRef}
          ></Marker>
        </MapContainer>
      </div>
      <Fab
        sx={classes.locationIcon}
        color="primary"
        onClick={getPosition}
      >
        <MyLocationIcon sx={classes.extendedIcon} />
      </Fab>
    </>
  )
}

export default Mapa
