import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@mui/material'
import SliderComponent from 'components/ResiembraVicia/Slider'
import React, { useEffect, useState } from 'react'

const yearsParameters = [
  'Cereal con herbicida (control total)',
  'Cereal con herbicida (escapes)',
  'Vicia heno',
  'Vicia pastoreo intenso',
  'Vicia pastoreo racional',
]

const YearSecuence = ({ handleChange, year }) => {
  const classes = {
    formControl: {
      // minWidth: 280,
      minWidth: 150,
    },
  }
  const [secuence, setSecuence] = useState('')
  const [yearVal, setYearVal] = useState(0)

  useEffect(() => {
    if (year === 2) {
      setSecuence(3)
      setYearVal(15)
      handleChange(15)
    } else if (year === 3) {
      setSecuence(4)
      setYearVal(140)
      handleChange(140)
    } else if (year === 4) {
      setSecuence(2)
      setYearVal(0)
    }
  }, [year, handleChange])

  const changeSecuence = e => {
    let val = e.target.value
    let value = 0
    setSecuence(val)
    if (val === 1) value = 10
    if (val === 4) value = 140
    if (val === 3) value = 15
    setYearVal(value)
    handleChange(value)
  }

  const changeSlider = (e, val) => {
    setYearVal(val)
    handleChange(val)
  }

  return (
    <Grid container justify="center" direction="row" alignItems="center">
      <Grid item xs={12} sm={4}>
        <FormControl sx={classes.formControl}>
          <InputLabel id="system-selector-label">{`Secuencia año ${year}`}</InputLabel>
          <Select
            labelId="system-selector-label"
            id="system-select-helper"
            value={secuence}
            onChange={changeSecuence}
          >
            <MenuItem value="" selected disabled>
              <em>Ninguno</em>
            </MenuItem>
            <MenuItem value={0}>{yearsParameters[0]}</MenuItem>
            <MenuItem value={1}>{yearsParameters[1]}</MenuItem>
            <MenuItem value={2}>{yearsParameters[2]}</MenuItem>
            <MenuItem value={3}>{yearsParameters[3]}</MenuItem>
            <MenuItem value={4}>{yearsParameters[4]}</MenuItem>
          </Select>
          <FormHelperText>{`valor: ${yearVal}`}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8}>
        {secuence === 3 && (
          <SliderComponent
            min={0}
            max={34}
            defVal={15}
            handleChange={changeSlider}
          />
        )}
        {secuence === 4 && (
          <SliderComponent
            min={60}
            max={280}
            defVal={140}
            handleChange={changeSlider}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default YearSecuence
