import React from 'react'
import dateFormat from 'dateformat'
import { sortBy } from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import PageContainer from 'components/Shared/PageContainer'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import Chart from './Chart'
import NewEntry from './NewEntry'
import EntriesTable from './EntriesTable'

const classes = {
  'root': {
    mt: 15,
    flexGrow: 1,
    minHeight: 400,
  }
}

const CargaDatos = ({
  nombreCampo,
  nombreLote,
  entradas,
  agregarNuevasEntradas,
  idLote,
  listadoMalezas,
}) => {
  
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

  const idMalezas = entradas.map(entrada => entrada.idMaleza)

  const entriesData = entradas[selectedTabIndex].entradas

  const sortedEntries = sortBy(entriesData, entry => {
    const [day, month, year] = entry.fecha.split('-')
    return new Date(year, month, day)
  })

  const saveData = ({ selectedDate, area1, area2, area3 }) => {
    const date = dateFormat(selectedDate, 'dd/mm/yyyy')
    const idMalezaSeleccionada = idMalezas[selectedTabIndex]

    const newData = {
      fecha: date,
      idLote,
      idMaleza: idMalezaSeleccionada,
      area1: area1 ? parseFloat(area1) : null,
      area2: area2 ? parseFloat(area2) : null,
      area3: area3 ? parseFloat(area3) : null,
    }

    agregarNuevasEntradas([newData])
  }

  const handleTabChange = (_, newValue) => {
    setSelectedTabIndex(newValue)
  }

  let acumulado = 0
  const tableData = sortedEntries.map(data => {
    const datosCargadosValidos = [data.area1, data.area2, data.area3].filter(
      data => data !== null,
    )

    let promedio = datosCargadosValidos.reduce(
      (acc, current) => acc + current,
      0,
    )
    promedio = promedio / datosCargadosValidos.length

    const roundedPromedio = Math.round((promedio + Number.EPSILON) * 100) / 100
    acumulado += roundedPromedio
    const roundedAcumulado =
      Math.round((acumulado + Number.EPSILON) * 100) / 100

    return {
      ...data,
      promedio: roundedPromedio,
      acumulado: roundedAcumulado,
    }
  })

  const sorted = [...tableData]
  sorted.reverse()

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" component={RouterLink} to="/users/panel">
          Sitios
        </Link>
        <Typography>{nombreCampo}</Typography>
        <Typography color="textPrimary">{nombreLote}</Typography>
      </Breadcrumbs>

      <Paper sx={classes.root}>
        <Tabs
          value={selectedTabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {idMalezas.map((idMaleza, index) => {
            const nombreMaleza =
              listadoMalezas.find(maleza => maleza.id === idMaleza)?.name ||
              '[err]'
            return <Tab key={index} label={nombreMaleza} />
          })}
        </Tabs>
        <NewEntry onNewEntry={saveData} />
        <Grid
          container
          spacing={1}
          style={{ padding: 30 }}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={8}>
            <EntriesTable data={sorted} />
          </Grid>
          <Grid item xs={8}>
            <Chart data={tableData} />
          </Grid>
        </Grid>
      </Paper>
    </PageContainer>
  )
}

export default CargaDatos
