import { Link, Typography } from '@mui/material'
import React from 'react'

import { getLocations, getWeather } from 'utils/server/serverWeather'
import WeatherComponent from './WeatherComponent'

const Weather = () => {
  const descriptionWeather = (
    <Typography component="p" variant="caption" style={{ margin: 20 }}>
      Datos basados en registros del{' '}
      <Link href="http://siga2.inta.gov.ar/#/" target="_blank">
        SIGA
      </Link>{' '}
      hasta el 10/5/2021. Datos de{' '}
      <Link href={'https://openweathermap.org/'} target="_blank">
        OpenWeatherMap
      </Link>{' '}
      a partir de 11/5/2021.
    </Typography>
  )
  const descriptionForecast = (
    <Typography component="p" variant="caption" style={{ margin: 20 }}>
      Datos generados mediante predicciones climáticas proporcionadas por{' '}
      <Link href={'https://openweathermap.org/'} target="_blank">
        OpenWeatherMap
      </Link>
      .
    </Typography>
  )

  return (
    <WeatherComponent
      getLocations={getLocations}
      getWeather={getWeather}
      descriptionWeather={descriptionWeather}
      descriptionForecast={descriptionForecast}
    />
  )
}

export default Weather
