import { useQueryCache, useMutation } from 'react-query'
import { nuevasEntradas } from 'utils/server/server'

export const useAgregarNuevasEntradas = () => {
  const cache = useQueryCache()

  const [mutate] = useMutation(nuevasEntradas, {
    onMutate: variables => {
      const { idLote, idMaleza } = variables.entradas[0]

      const datosPrevios = cache.getQueryData(['get-entradas', idLote])
      const indiceEntradasPrevias = datosPrevios.entradas.datos.findIndex(
        entradaPorMaleza => entradaPorMaleza.idMaleza === idMaleza,
      )

      const datosNuevos = { ...datosPrevios }

      const entradasPrevias =
        datosPrevios.entradas.datos[indiceEntradasPrevias].entradas
      let entradasNuevas = [...entradasPrevias]
      const entradasNuevasConFechaFormateada = variables.entradas.map(
        entrada => ({
          ...entrada,
          fecha: entrada.fecha.replaceAll('/', '-'),
        }),
      )

      entradasNuevasConFechaFormateada.forEach(entrada => {
        const indiceEntradaPrevia = entradasNuevas.findIndex(
          previa => previa.fecha === entrada.fecha,
        )

        if (indiceEntradaPrevia !== -1) {
          entradasNuevas[indiceEntradaPrevia] = entrada
          return
        }

        entradasNuevas = [...entradasNuevas, entrada]
      })

      datosNuevos.entradas.datos[indiceEntradasPrevias].entradas =
        entradasNuevas

      cache.setQueryData(['get-entradas', idLote], datosNuevos)

      return datosPrevios
    },
    onError: (err, variables, previousValue) => {
      const { idLote } = variables.entradas[0]
      cache.setQueryData(['get-entradas', idLote], previousValue)
    },
  })

  const agregarNuevasEntradas = async entradas => {
    await mutate({
      entradas,
    })
  }

  return agregarNuevasEntradas
}
