import { getMalezas } from 'utils/server/server'
import { useQuery } from 'react-query'

export const useMalezasQuery = () => {
  const { isLoading, data, error } = useQuery('get-malezas', getMalezas)

  return {
    malezas: data?.malezas || [],
    loading: isLoading,
    error,
  }
}
