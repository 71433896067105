import React, { PureComponent,useState } from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

import {
  Chart
} from 'react-chartjs-2';
  

import 'chartjs-plugin-annotation'

import { defaultOptions, addShadowBox } from 'utils/chartsConfig'


function CustomChart(props) {

  const {
    labels,
    cumulative,
    diary,
    tmax,
    tmin,
    rain,
  } = props

  const [show,setShow] = useState({'diaria':true,'tempMax':false,'tempMin':false,'acumulada':true,'rain':false});
  const [key,setKey] = useState(Math.floor(Math.random()*10000));

  const activeAxis = Object.entries(show).filter(x=>x[1]);

  console.log("New shit");
  console.log(activeAxis);

  const data = {
    labels,
    datasets: [
      {
        label: 'Emergencia Acumulada',
        data: cumulative,
        // Changes this dataset to become a line
        type: 'line',
        fill: false,
        backgroundColor: '#3498db',
        borderColor: '#3498db',
        yAxisID: show['acumulada']?'acumulada':(activeAxis.length>0?activeAxis[0][0]:'dummy'),
        hidden: !show['acumulada'],
        lineTension: 0,
      },
      {
        label: 'Emergencia Diaria',
        data: diary,
        backgroundColor: '#2ecc71',
        yAxisID: show['diaria']?'diaria':(activeAxis.length>0?activeAxis[0][0]:'dummy'),
        hidden: !show['diaria'],
      },
      {
        type: 'line',
        label: 'Temperatura Máxima',
        data: tmax,
        backgroundColor: '#e84118',
        borderColor: '#e84118',
        fill: false,
        display: show['temp'],
        lineTension: 0,
        hidden: !show['tempMax'],
        yAxisID: show['tempMax']?'temp':(activeAxis.length>0?activeAxis[0][0]:'dummy'),
      },
      {
        type: 'line',
        label: 'Temperatura Mínima',
        data: tmin,
        backgroundColor: '#00a8ff',
        borderColor: '#00a8ff',
        fill: false,
        lineTension: 0,
        display: show['tempMin'],
        hidden: !show['tempMin'],
        yAxisID: show['tempMin']?'temp':(activeAxis.length>0?activeAxis[0][0]:'dummy'),
      },
      {
        // type: 'line',
        label: 'Precipitación',
        data: rain,
        backgroundColor: '#7f8fa6',
        borderColor: '#7f8fa6',
        fill: false,
        lineTension: 0,
        display: show['rain'],
        hidden: !show['rain'],
        yAxisID: show['rain']?'rain':(activeAxis.length>0?activeAxis[0][0]:'dummy'),
      },
    ],
  }

  const scales = {};

  if(show['diaria']){
    scales['diaria'] = {
      type: 'linear',
      display: show['diaria'],
      position: 'left',
      gridLines: {
        display: show['diaria'],
      },
      scaleLabel: {
        display: show['diaria'],
        labelString: 'Emergencia Diaria',
      },
      labels: {
        show: show['diaria'],
      },
      title:{
        display:show['diaria'],
        text:'Probabilidad de emergencia'
      },
      ticks: {
        min: 0,
        // max: 1E-1,
      },
    }
  }
  if(show['acumulada']){
    scales['acumulada']={
      type: 'linear',
      display: true,
      position: 'left',
      gridLines: {
        display: false,
      },
      labels: {
        show: true,
      },
      scaleLabel: {
        display: true,
        labelString: 'Emergencia Acumulada',
      },
      title:{
        display:true,
        text:'Probabilidad de emergencia acumulada'
      },
    }
  }
  if(show['rain']){
    scales['rain'] = {
      type: 'linear',
      display: true,
      position: 'right',
      gridLines: {
        display: false,
      },
      labels: {
        show: true,
      },
      scaleLabel: {
        display: true,
        labelString: 'Precipitación [mm]',
      },
      title:{
        display:true,
        text:'Precipitación [mm]'
      },
      ticks: {
        beginAtZero: true,
      },
    }
  }
  if(show['tempMax']||show['tempMin']){
    scales['temp'] = {
      type: 'linear',
      display: true,
      position: 'right',
      gridLines: {
        display: false,
      },
      labels: {
        show: true,
      },
      scaleLabel: {
        display: true,
        labelString: 'Temperatura [°C]',
      },
      title:{
        display:true,
        text:'Temperatura [°C]'
      },
    }
  }

   const  customRadius = function customRadius( context )
  {
    let index = context.dataIndex;
    return index===0?0:20;
  }

    if (
      !labels.length ||
      !cumulative.length ||
      !diary.length ||
      !tmax.length ||
      !tmin.length ||
      !rain.length
    )
      return  <p>Error al cargar al gŕafico</p>

    console.log("FROM CHART");
    console.log(data);
    console.log(scales);
    console.log(show);

    const dataSetAxisMap = {
      'Emergencia Diaria':'diaria',
      'Emergencia Acumulada':'acumulada',
      'Temperatura Máxima':'tempMax',
      'Temperatura Mínima':'tempMin',
      'Precipitación':'rain'
    }

    console.log(Object.entries(dataSetAxisMap));

    const options = {
      ...defaultOptions,
      plugins: {
        legend: {
          onClick: (event,legendItem,legend)=>{
            ChartJS.defaults.plugins.legend.onClick(event,legendItem,legend);
            const scaleName = Object.entries(dataSetAxisMap).find((keyValueArray)=>{return keyValueArray[0]==legendItem.text});
            show[scaleName[1]] = !legendItem.hidden;
            setShow(show);
            setKey(Math.floor(Math.random()*10000));
          },
        }
      },
      // elements:{ 
      //   radius : customRadius,
      //       display: true
      // },
      scales: scales,
      // ...addShadowBox(minDayBox),
    }

    return <Chart key={key} type='bar' data={data} options={options} />
  }

export default CustomChart
