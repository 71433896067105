import React from 'react'
import CargaDatos from 'components/CargaDatos'
import { useParams } from 'react-router-dom'
import { useEntradasQuery } from './useEntradasQuery'
import { useAgregarNuevasEntradas } from './useAgregarNuevasEntradas'
import { useMalezasQuery } from '../Shared/getMalezas'
import Spinner from 'components/Shared/Spinner'

const PaginaCargaDatos = () => {
  const { idLote } = useParams()
  const { loading, entradas, error } = useEntradasQuery(idLote)
  const { malezas } = useMalezasQuery()

  const agregarNuevasEntradas = useAgregarNuevasEntradas()

  if (loading) return <Spinner />
  if (error) return `Error cargando pagina: ${error}`

  const { nombreCampo, nombreLote, datos } = entradas

  return (
    <CargaDatos
      nombreCampo={nombreCampo}
      nombreLote={nombreLote}
      entradas={datos}
      idLote={idLote}
      agregarNuevasEntradas={agregarNuevasEntradas}
      listadoMalezas={malezas}
    />
  )
}

export default PaginaCargaDatos
