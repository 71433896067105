import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const HowDoesItWork = () => {
  return (
    <React.Fragment>
      <Typography variant="body1">
        La adecuada predicción de la emergencia de las malezas a campo es uno de
        los requerimientos esenciales para su control efectivo. Este sistema
        permite{' '}
        <b>
          predecir la emergencia de algunas de las principales malezas del
          sudoeste bonaerense
        </b>{' '}
        basado en los modelos propuestos en [1] y utilizando predicciones
        meteorológicas de la región. Específicamente se emplean pronósticos de
        los siguientes datos del estado del tiempo:
      </Typography>
      <Typography
        variant="body1"
        component="ul"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <li>Temperatura mínima diaria</li>
        <li>Temperatura máxima diaria</li>
        <li>Precipitación diaria</li>
      </Typography>
      <Typography variant="body1">
        Sobre la base de estas predicciones se estima la{' '}
        <b>emergencia futura</b>, la cual se{' '}
        <b>reporta en forma diaria y acumulada</b>. El sistema tiene una
        mecánica de horizonte móvil:
      </Typography>
      <Typography
        variant="body1"
        component="ol"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <li>
          Entre el 1ro de enero y el día de ayer, la emergencia se calcula
          utilizando datos de registros meteorológicos.
        </li>
        <li>
          Entre el día de hoy y el 31 de diciembre, la emergencia se calcula
          empleando el pronóstico del estado del tiempo
        </li>
      </Typography>
      <Typography variant="h6" gutterBottom>
        Referencias
      </Typography>
      <Typography variant="body1">
        1. G. R. Chantre, M. R. Vigna, J.P. Renzi, A. M. Blanco (2018); A
        flexible and practical approach for real-time weed emergence prediction
        based on Artificial Neural Networks. Bio systems Engineering, 170, 51-
        60. -{' '}
        <Link
          href="https://www.sciencedirect.com/science/article/pii/S1537511017306335"
          target="_blank"
          rel="noopener noreferrer"
        >
          consultar paper
        </Link>
      </Typography>
    </React.Fragment>
  )
}

export default HowDoesItWork
