import { useQuery } from 'react-query'
import { getCampos } from 'utils/server/server'

const mapCampos = campo => {
  return {
    ...campo,
    malezas: campo.malezas.split(','),
  }
}

export const useCamposQuery = () => {
  const { isLoading, data, error } = useQuery('get-campos', getCampos)

  return {
    campos: data?.campos.map(mapCampos) || [],
    loading: isLoading,
    error,
  }
}
