import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Link } from 'react-router-dom'
import { signupUser } from '../../utils/server/server'
import { useNavigate } from 'react-router-dom'
import ErrorNotification from 'components/Shared/ErrorNotification'
import ScreenLoading from 'components/Shared/ScreenLoading'

const Signup = () => {
  const classes = {
    'paper' :  {
      mt: 9,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    'avatar' : {
      m: 1,
      backgroundColor: secondary.main,
    },
    'form': {
      width: '100%', // Fix IE 11 issue.
      height: '100vh',
      mt: 4,
    },
    'submit': {
      mt: 5,
      ml: 2
    }};
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = useState(false)

  const handleSignup = async e => {
    e.preventDefault()

    setShowSpinner(true)
    try {
      await signupUser(name, email, password)
      navigate('/users/login')
    } catch (e) {
      console.error('Error signing up:', e)
      const errorMessage = e.response ? e.response.data.message : e.message
      setErrorMessage(errorMessage)
    } finally {
      setShowSpinner(false)
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div sx={classes.paper}>
          <Avatar sx={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Crear nueva cuenta
          </Typography>
          <form sx={classes.form} onSubmit={handleSignup}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Nombre"
                  name="name"
                  onChange={e => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Crear cuenta
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link variant="body2" to="/users/login">
                  ¿Ya tenés una cuenta? Iniciá sesión.
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <ErrorNotification
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <ScreenLoading open={showSpinner} />
    </>
  )
}

export default Signup
