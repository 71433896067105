import React from 'react'
import LineChart from '../Shared/LineChart'
import 'chartjs-plugin-annotation'
import blue from '@mui/material/colors/blue'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import orange from '@mui/material/colors/orange'

import { formatChartDate, defaultLinesStyle } from '../../utils/chartsConfig'
import { getFiveDaysFromNowDate } from '../../utils/datetime'

const ChartAcumulado = ({ chartData }) => {
  const labels = chartData.map(entry => formatChartDate(entry.day))
  const plantulasData = chartData.map(entry => entry.plantulas)
  const vegetativoTempranoData = chartData.map(
    entry => entry.vegetativoTemprano,
  )
  const vegetativoAvanzadoData = chartData.map(
    entry => entry.vegetativoAvanzado,
  )
  const reproductivoInicialData = chartData.map(
    entry => entry.reproductivoInicial,
  )

  const data = {
    labels,
    datasets: [
      {
        ...defaultLinesStyle(blue[500]),
        label: 'Plantulas',
        fill: true,
        data: plantulasData,
      },
      {
        ...defaultLinesStyle(red[500]),
        label: 'Vegatativo Temprano',
        fill: true,
        data: vegetativoTempranoData,
      },
      {
        ...defaultLinesStyle(green[500]),
        label: 'Vegatativo Avanzado',
        fill: true,
        data: vegetativoAvanzadoData,
      },
      {
        ...defaultLinesStyle(orange[500]),
        label: 'Reproductivo Inicial',
        fill: true,
        data: reproductivoInicialData,
      },
    ],
  }

  const shadowBoxStartDay = getFiveDaysFromNowDate()
  const formattedShadowBoxStartDay = formatChartDate(shadowBoxStartDay)

  return (
    <LineChart
      data={data}
      title="Acumulado (Estado fenológico) vs Tiempo"
      shadowBoxStartDay={formattedShadowBoxStartDay}
    />
  )
}

export default ChartAcumulado
