import React from 'react'
import PropTypes from 'prop-types'

import { List, Drawer, Divider } from '@mui/material'
import { ListItem, ListItemText, ListSubheader } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const classes = {
  'list': {
    width: 250,
  }
}

function ListItemLink(props) {
  const { primary, to } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  )

  return (
    <ListItem button component={renderLink}>
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const SideBarMenu = ({ open, setOpen }) => {
  

  return (
    <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
      <List
        sx={classes.list}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <ListSubheader>Sistema MIM</ListSubheader>

        <ListItemLink button primary="Pantalla principal" key="home" to="/" />

        <Divider />

        <ListItemLink
          button
          primary="Pronóstico malezas"
          key="malezas"
          to="/pronostico-maleza"
        />
        <ListItemLink
          button
          primary="Pronóstico cultivo-malezas"
          key="cultivo-malezas"
          to="/cultivo-maleza"
        />
        <ListItemLink
          button
          primary="Temperatura y Humedad de Suelos"
          key="calculos-suelo"
          to="/calculos-suelo"
        />
        <ListItemLink
          button
          primary="Información meteorológica OpenWeatherMap"
          key="clima"
          to="/clima"
        />
        <ListItemLink
          button
          primary="Información meteorológica MeteoBahia"
          key="climaMeteo"
          to="/clima-meteo"
        />
        <ListItemLink
          button
          primary="Resiembra vicia"
          key="resiembraVicia"
          to="/resiembra-vicia"
        />
        <Divider />
        <ListItemLink
          button
          primary="Quienes somos"
          key="about-us"
          to="/about"
        />
      </List>
    </Drawer>
  )
}

SideBarMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

export default SideBarMenu
