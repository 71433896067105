import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import { defaultOptions, addShadowBox } from '../../utils/chartsConfig'

const classes = {
  chartWrapper: {
    minWidth: 500,
  },
}

const BarChart = ({
  data,
  title = '',
  showLegends = true,
  shadowBoxStartDay = null,
  options,
}) => {
  

  const chartTitle = {
    title: {
      ...defaultOptions.title,
      display: !!title,
      text: title,
    },
  }

  const chartOptions = {
    ...defaultOptions,
    ...chartTitle,
    ...options,
    ...(shadowBoxStartDay && addShadowBox(shadowBoxStartDay)),
    legend: {
      display: showLegends,
    },
  }

  return (
    <div sx={classes.chartWrapper}>
      <Bar data={data} options={chartOptions} />
    </div>
  )
}

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  showLegends: PropTypes.bool,
  shadowBoxStartDay: PropTypes.string,
  options: PropTypes.object,
}

export default BarChart
