import React from 'react'

import WeatherComponent from 'components/Weather/WeatherComponent'
import {
  getLocationsMeteo,
  getWeatherMeteo,
} from 'utils/server/serverWeatherMeteo'
import { Link, Typography } from '@mui/material'

const Weather = () => {
  const descriptionWeather = (
    <Typography component="p" variant="caption" style={{ margin: 20 }}>
      Datos generados mediante predicciones climáticas proporcionadas por{' '}
      <Link href={'https://www.meteobahia.com.ar/'} target="_blank">
        MeteoBahia
      </Link>
      .
    </Typography>
  )
  const descriptionForecast = (
    <Typography component="p" variant="caption" style={{ margin: 20 }}>
      Datos generados mediante predicciones climáticas proporcionadas por{' '}
      <Link href={'https://www.meteobahia.com.ar/'} target="_blank">
        MeteoBahia
      </Link>
      .
    </Typography>
  )

  return (
    <WeatherComponent
      getLocations={getLocationsMeteo}
      getWeather={getWeatherMeteo}
      descriptionWeather={descriptionWeather}
      descriptionForecast={descriptionForecast}
    />
  )
}

export default Weather
