import React from 'react'

import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import Maleza from 'components/Shared/Maleza'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, malezas) {
  return {
    fontWeight: malezas.indexOf(name) === -1 ? 'normal' : 'bold',
  }
}

const classes = {
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

const SelectMalezas = ({ listadoMalezas, malezas, setMalezas }) => {
  

  const handleChange = event => {
    setMalezas(event.target.value)
  }

  return (
    <FormControl sx={classes.formControl} margin="normal">
      <InputLabel id="demo-mutiple-chip-label">Malezas</InputLabel>
      <Select
        multiple
        fullWidth
        value={malezas}
        onChange={handleChange}
        input={<Input />}
        renderValue={selectedIds => (
          <div sx={classes.chips}>
            {selectedIds.map(selectedId => {
              const maleza = listadoMalezas.find(
                maleza => maleza.id === selectedId,
              )

              return <Maleza key={selectedId} nombre={maleza.name} />
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {listadoMalezas.map(maleza => (
          <MenuItem
            key={maleza.id}
            value={maleza.id}
            style={getStyles(maleza.name, malezas)}
          >
            {maleza.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectMalezas
