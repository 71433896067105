import dateFormat from 'dateformat'

export const defaultOptions = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || ''

        if (label) {
          label += ': '
        }

        label += Math.round(tooltipItem.yLabel * 100) / 100
        return label
      },
    },
  },
  title: {
    display: false,
    text: '',
    fontSize: 18,
    padding: 20,
  },
}

export const defaultLinesStyle = lineColor => ({
  fill: false,
  lineTension: 0.1,
  backgroundColor: lineColor,
  borderColor: lineColor,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: lineColor,
  pointBackgroundColor: lineColor,
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: lineColor,
  pointHoverBorderColor: lineColor,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
})

export const addShadowBox = startDay => ({
  annotation: {
    annotations: [
      {
        drawTime: 'afterDatasetsDraw',
        type: 'box',
        xScaleID: 'x-axis-0',
        yScaleID: 'y-axis-0',
        xMin: startDay,
        xMax: '30/12',
        backgroundColor: 'rgba(225, 226, 230, 0.5)',
        borderColor: 'rgb(220, 221, 225)',
        borderWidth: 1,
      },
    ],
  },
})

export const formatChartDate = unformattedDate => {
  return dateFormat(unformattedDate, 'dd/mm')
}
