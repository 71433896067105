import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import AddIcon from '@mui/icons-material/Add'

import CampoCard from './CampoCard'

const classes = {
  cardGrid: {
    pt: 8,
    pb: 8,
    minHeight: 'calc(100vh - 225px)',
  },
  card: {
    height: 252,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContentCentered: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const PanelUsuario = ({ campos, listadoMalezas }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const tarjetasCampos = []
  campos.forEach((campo, indexCampo) => {
    tarjetasCampos.push([
      ...campo.lotes.map((lote, indexLote) => {
        const malezas = campo.malezas.map(idMaleza => {
          const nombre =
            listadoMalezas.find(maleza => maleza.id === idMaleza)?.name ||
            '[err]'

          return {
            nombre,
            id: idMaleza,
          }
        })

        return (
          <Grid key={`${indexCampo}${indexLote}`} item xs={12} sm={6} md={6}>
            <CampoCard
              nombreCampo={campo.nombre}
              nombreLote={lote.name}
              localidad={campo.localidad}
              latLng={campo.latitudLongitud}
              malezas={malezas}
              listadoMalezas={listadoMalezas}
              onCargaDatosClicked={() =>
                navigate(`/users/carga-datos/${lote.id}`)
              }
            />
          </Grid>
        )
      }),
    ])
  })

  return (
    <main>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={8}>
          {tarjetasCampos}
          <Grid key="add" item xs={12} sm={6} md={6}>
            <Card
              className={classes.card}
              component={RouterLink}
              to="/users/nuevo-sitio"
            >
              <CardContent className={classes.cardContentCentered}>
                <AddIcon style={{ fontSize: 80 }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}

PanelUsuario.propTypes = {
  campos: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      lotes: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
      ).isRequired,
      localidad: PropTypes.string.isRequired,
      latitudLongitud: PropTypes.string.isRequired,
      malezas: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
}

export default PanelUsuario
