import React, { Component , useState} from 'react'
import dateFormat from 'dateformat'
import Typography from '@mui/material/Typography'

import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'

import CustomChart from './Chart';
import ButtonPicker from './ButtonPicker';
import { Chart } from "react-google-charts";
import InputData from 'components/PronosticoMalezas/InputData';
import HowDoesItWork from 'components/PronosticoMalezas/HowDoesItWork';
import PageContainer from 'components/Shared/PageContainer';
import './PronosticoMalezas.css';

import { getGraphData } from 'utils/server/server.js';
import { DateRangeSharp } from '@mui/icons-material'

const weeds = [
  'Avena Fatua',
  'Lolium Multiflorum',
  'Vicia Villosa',
  'Amaranthus palmeri',
  'Cenchrus incertus',
  'Conyza bonariensis',
]

const locationsByWeed = {
  'Avena Fatua': [
    'Bordenave (Buenos Aires) – EEA INTA Bordenave (37.75, -63.08)',
  ],
  'Lolium Multiflorum': [
    'Bordenave (Buenos Aires) – EEA INTA Bordenave (37.75, -63.08)',
  ],
  'Vicia Villosa': [
    'Hilario Ascasubi (Buenos Aires) – EEA INTA Ascasubi (-39.396, -62.626)',
  ],
  'Amaranthus palmeri': ['General Pico (La Pampa) - (-35.659, -63.767)'],
  'Cenchrus incertus': [
    'Anguil (La Pampa) - EEA INTA Anguil  (-36.54, -63.99)',
  ],
  'Conyza bonariensis': [
    'Anguil (La Pampa) - EEA INTA Anguil  (-36.54, -63.99)',
  ],
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

class App extends Component {

  periodMap = ()=>{return{'oneweek':7,'twoweeks':14,"onemonth":30,"twomonth":60,
  'threemonth':90,'sixmonth':180,'year':365};};

  addDays = (date,days)=>{
    const newDate = new Date(date);
    newDate.setDate(date.getDate()+days);
    console.log("From Day Adding:");
    console.log(date);
    console.log(date.getDate());
    console.log(days);
    console.log(newDate);
    return newDate;
  };

  constructor(props){
    super(props);

    const now = new Date();
    const minDate = this.addDays(now,-365);
    const maxDate = this.addDays(minDate,this.periodMap()['oneweek']);
    this.state = {
      data: null,
      labels: [],
      cumulative: [],
      diary: [],
      tmax: [],
      tmin: [],
      rain: [],
      todayLabels: [],
      todayCumulative: [],
      todayDiary: [],
      todayTMax: [],
      todayTMin: [],
      todayRain: [],
      todayPercentile: 0,
      error: null,
      resolution: 'oneday',
      period:'oneweek',
      minDate:minDate,
      maxDate:maxDate,
      selectedGraph: 0,
      drawerOpen: false,
      selectedWeed: weeds[0],
      selectedLocation: locationsByWeed[weeds[0]][0],
      loading: false,
    }
  }

  

    processServerResponse(serverResponse,dateMin,dateMax) {
    let labels = []
    let cumulative = []
    let diary = []
    let tmax = []
    let tmin = []
    let rain = []

    serverResponse.forEach(entry => {
      if (entry !== undefined) {
        const date = entry.day
        const testDate = new Date(date);
        console.log("FROM FILTERING:");
        console.log(entry);
        console.log(new Date(date));
        console.log(dateMin);
        console.log(dateMax);
        console.log(testDate>=dateMin);
        console.log(testDate<=dateMax);
        if(testDate>=dateMin && testDate<=dateMax){
          const formattedDate = this.formatDate(date);
          labels.push(formattedDate);
          cumulative.push(entry.cumulative);
          diary.push(entry.diary);
          tmax.push(entry.t_max);
          tmin.push(entry.t_min);
          rain.push(entry.rain);
        }
      }
    });

    return { labels, cumulative, diary, tmax, tmin, rain};
  }

  async componentDidMount() {
    const weed = this.state.selectedWeed
    console.log("FROM COMPONENT DID MOUNT");
    console.log(weed)
    this._getGraphData(weed)
  }

  getHeaders(){
    return ['Fecha','Emergencia Acumulada','Emergencia Diaria','Temperatura Máxima [°C]','Temperatura Mínima [°C]','Precipitación [mm]'];
  }

  downFunct = function(data,title){
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    // return url;
    // // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // // Passing the blob downloading url
    a.setAttribute('href', url)
    // // Setting the anchor tag attribute for downloading
    // // and passing the download file name
    a.setAttribute('download', `${title}.csv`);
    // // Performing a download with click
    a.click()
  }

  changeRangeChart = function(){
    const index = Math.floor(Math.random()*this.state.diary.length);
    console.log("FROM CHANGE RANGE CHART:");
    console.log("INDEX WAS:");
    console.log(index);
    const newArr = this.state.diary.slice(index);
    console.log("NEW ARRAY IS:");
    console.log(newArr);
    this.setState({
      selectedDiary:newArr
    });
  }

  makeCSV(labels,cumulative,diary,tmax,tmin,rain){
    let csvRows = [];
    const headers = this.getHeaders();
    csvRows.push(headers.join(','));
    console.assert(
      (labels.length === cumulative.length) && 
      (cumulative.length === diary.length) && 
      (diary.length === tmax.length) && 
      (tmax.length === tmin.length) &&
      (tmin.length === rain.length)
    );
    labels.forEach((x,i)=>{
      csvRows.push([labels[i],cumulative[i],diary[i],tmax[i],tmin[i],rain[i]].join(','));
    })
    return csvRows.join('\n');
  }

  _getGraphData = async weed => {
    let data, error

    // If it takes more than 1 sec to load, show a spinner.
    const timeout = setTimeout(() => this.setState({ loading: true }), 1000);

    try {
      ;({ data } = await getGraphData(weed))
      this.setState({ error: null })
    } catch (err) {
      error = err
    }

    console.log("FROM GET GRAPH DATA");
    console.log(data);

    clearTimeout(timeout)

    if (error || !Array.isArray(data)) {
      this.setState({ error: error ?? 'Data is unavailable', loading: false });
      return
    }

    const today = this._getDate()
    let todayIndex;
    data.find((el, index) => {
      todayIndex = index
      return this.formatDate(el.day) === today
    })

    let todayGraphData = []
    for (let i = todayIndex; i <= todayIndex + 7; ++i) {
      todayGraphData.push(data[i])
    }

    this.setState({'data':data});

    const { labels, cumulative, diary, tmax, tmin, rain } =
      this.processServerResponse(data,this.state.minDate,this.state.maxDate);

    console.log("From initialization");
    console.log(this.state.minDate);
    console.log(this.state.maxDate);
    console.log(diary);
    console.log(cumulative);
    console.log(labels);
    console.log(tmax);
    console.log(tmin);
    console.log(rain);

    const csvString = this.makeCSV(labels, cumulative, diary, tmax, tmin, rain);

      // const {
      //   labels: todayLabels,
      //   cumulative: todayCumulative,
      //   diary: todayDiary,
      //   tmax: todayTMax,
      //   tmin: todayTMin,
      //   rain: todayRain,
      // } = this.processServerResponse(todayGraphData)

    this.setState({
      labels,
      cumulative,
      selectedDiary:diary,
      tmax,
      tmin,
      rain,
      // todayLabels,
      // todayCumulative,
      // todayDiary,
      // todayTMax,
      // todayTMin,
      // todayIndex,
      // todayRain,
      loading: false,
      //TODO: pass as prop
      csvString,
      diary,
    })    
  }

  getRawTodayDate = () =>{
    const now = new Date()
    return now;
  }

  _getDate = () => {
    const now = new Date()
    const date = this.formatDate(now)
    return date
  }

  formatDate(date) {
    return dateFormat(date, 'dd/mm')
  }

  handleChange = (event, value) => {
    this.setState({ selectedGraph: value })
  }

  handleChartRangeModeChange = (event,value)=>{
    
    const now = new Date();
    let minDate = new Date(now.setDate(now.getDate()-365));
    let maxDate = this.addDays(minDate,this.periodMap()[value]);

    this.setState({period:value,minDate:minDate,maxDate:maxDate});

    const { labels, cumulative, diary, tmax, tmin, rain } =
    this.processServerResponse(this.state.data,minDate,maxDate);

    console.log("FROM CHART RANGE HANDLER");
    console.log("VALUE RECEIVED WAS:");
    console.log(value);
    console.log(minDate);
    console.log(maxDate);
    console.log(diary);

    this.setState({labels,
      cumulative,
      selectedDiary:diary,
      tmax,
      tmin,
      rain,});
  }

  handleChartResolutionChange = (event,value)=>{
    console.log("FROM CHART RESOLUTION HANDLER");
    console.log("VALUE RECEIVED WAS:");
    this.setState({resolution:value});
  }

  changeSelectedWeed = event => {
    const newSelectedWeed = event.target.value
    const newSelectedLocation = locationsByWeed[newSelectedWeed][0]
    this.setState({
      selectedWeed: newSelectedWeed,
      selectedLocation: newSelectedLocation,
    })

    this._getGraphData(newSelectedWeed)
  }

  changeSelectedLocation = event => {
    const newSelectedLocation = event.target.value
    this.setState({ selectedLocation: newSelectedLocation })
  }

  render() {
    console.log("FROM RENDER");
    console.log("state WAS:");
    console.log(this.state);
    const classes = {
      graph: {
        bgcolor: 'background.paper',
        marginBottom: 20,
      },
    };
    const { labels, diary, cumulative, tmax, tmin, rain, error } = this.state
    const {
      todayLabels,
      todayDiary,
      todayCumulative,
      todayTMax,
      todayTMin,
      todayRain,
      todayPercentile,
      todayPosition,
      todayIndex,
      csvString,
      selectedDiary
    } = this.state

    
    const { selectedGraph: selectedTab, loading } = this.state
    const { selectedWeed, selectedLocation } = this.state

    const LoadingOverlay = () => (
      <div className="Overlay">
        <CircularProgress />
      </div>
    )

    console.log("After state initalization:");
    console.log(labels);
    console.log(selectedDiary);
    console.log(error || labels.length==0);

    const chart = error || labels.length==0 ? (
      <p>Error al cargar al gŕafico</p>
    ) : (
      <CustomChart
        labels={labels}
        cumulative={cumulative}
        diary={selectedDiary}
        tmax={tmax}
        tmin={tmin}
        rain={rain}
        minDayBox={todayLabels[5]}
        todayDiary={todayDiary}
        todayLabel={todayLabels[0]}
        todayCumulative={todayCumulative}
      />
    )

    console.log("todayDiary WAS:");
    console.log(todayDiary);

    const gaugeChartDiary = (
        <div style={{paddingRight:"60px",paddingLeft:"13px"}}>
          <h3>Índice de emergencia {this._getDate()}:</h3>
          <h3><span style={{color:(todayPercentile).toFixed(2)>=90?'red':(todayPercentile).toFixed(2)>=75?'orange':'green'}}>
            {/* {(todayDiary[0]*100).toFixed(2)}% */}
            {(todayPercentile).toFixed(2)>=90?'Alto':(todayPercentile).toFixed(2)>=75?'Medio':'Bajo'}
          </span></h3>
          {/* <h3>Más alta que él <span style={{color:(todayPercentile).toFixed(2)>=90?'red':(todayPercentile).toFixed(2)>=75?'orange':'green'}}>
            {todayPercentile}%
            </span> de los días</h3> */}
          <Chart
            chartType="Gauge"
            width="100%"
            // height="400px"
            data={[
              ["Label","Value"],
              ["",todayPercentile]
            ]}
            options={{
              width: 200,
              // height: 120,
              redFrom: 90,
              redTo: 100,
              yellowFrom: 75,
              yellowTo: 90,
              minorTicks: 5,
            }}
          />
        </div>);
        const gaugeChartCumulative = (
        <div style={{padding:"20px"}}>
          <h3>Probabilidad de que ya haya emergido la maleza este año:</h3>
          <h3><span style={{color:(todayCumulative[0]*100).toFixed(2)>=90?'red':(todayCumulative[0]*100).toFixed(2)>=75?'orange':'green'}}>{(todayCumulative[0]*100).toFixed(2)}%</span></h3>
          <Chart
            chartType="Gauge"
            width="100%"
            // height="400px"
            data={[
              ["Label","Value"],
              ["",todayCumulative[0]*100],
            ]}
            options={{
              // width: 200,
              // height: 120,
              redFrom: 90,
              redTo: 100,
              yellowFrom: 75,
              yellowTo: 90,
              minorTicks: 5,
            }}
          />
        </div>
  );

    // const todayChart = error ? (
    //   <p>Error al cargar al gŕafico</p>
    // ) : (
    //   <CustomChart
    //     labels={todayLabels}
    //     cumulative={todayCumulative}
    //     diary={todayDiary}
    //     tmax={todayTMax}
    //     tmin={todayTMin}
    //     rain={todayRain}
    //   />
    // )

    // const buttonPicker = <FormControlLabel label='Período:' labelPlacement='start' sx={{'& .MuiFormControlLabel-label':{marginRight:1}}} 
    // control={
    //   <ToggleButtonGroup
    //     value={this.state.period}
    //     exclusive={true}
    //     onChange={this.handleChartRangeModeChange}
    //     aria-label="Rango del gráfico"
    //   >
    //     <ToggleButton value="oneweek" aria-label='Una semana'>
    //       Una Semana
    //     </ToggleButton>
    //     <ToggleButton value="twoweeks" aria-label='Dos semanas'>
    //       Dos Semanas
    //     </ToggleButton>
    //     <ToggleButton value="onemonth" aria-label='Un mes'>
    //       Un mes
    //     </ToggleButton>
    //   </ToggleButtonGroup>}
    // />;

    return (
      <PageContainer style={{width:'100vw'}}>
        <Typography variant="h5" gutterBottom component="h2">
          Predicción de emergencia
        </Typography>
        <Typography variant="h6" gutterBottom>
          Datos de entrada
        </Typography>
        <div style={{ marginBottom: 20, maxWidth: 700 }}>
          <InputData
            weed={
              <Select value={selectedWeed} onChange={this.changeSelectedWeed}>
                {weeds.map((weed, index) => (
                  <MenuItem key={index} value={weed}>
                    {weed}
                  </MenuItem>
                ))}
              </Select>
            }
            location={
              <Select
                value={selectedLocation}
                onChange={this.changeSelectedLocation}
              >
                {locationsByWeed[selectedWeed].map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        </div>
        <Typography variant="h6" gutterBottom>
          Predicción generada
        </Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'start',backgroundColor:"#ffffff"}}>
            {/* <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Pronóstico 7 días" />
              <Tab label="Gráfico datos" />
            </Tabs>
          </AppBar> */}
          {/* {selectedTab === 0 && ( */}
            {/* <TabContainer> */}
              {gaugeChartDiary}
            {/* </TabContainer> */}
          {/* )} */}
          {/* {selectedTab === 1 && ( */}
            {/* <TabContainer> */}
            <div style={{flexGrow:'1'}} sx={classes.graph}>
              <div style={{flexDirection:'row',flexGrow:'1',justifyContent:'space-between'}}>
              <ButtonPicker 
                label='Horizonte del gráfico' 
                value={this.state.period} 
                changeHandler={this.handleChartRangeModeChange}
                values={[
                  {label:"Una semana",value:"oneweek"},
                  {label:"Dos semanas",value:"twoweeks"},
                  {label:"Un mes",value:"onemonth"},
                  {label:"Dos meses",value:"twomonth"},
                  {label:"Tres meses",value:"threemonth"},
                  {label:"Seis meses",value:"sixmonth"},
                  {label:"Todo el año",value:"year"}
                ]}>
              </ButtonPicker>
              <ButtonPicker 
                label='Resolución' 
                value={this.state.resolution} 
                changeHandler={this.handleChartResolutionChange}
                values={[{label:"Diaria",value:"oneday"},{label:"Semanal",value:"oneweek"},{label:"Mensual",value:"onemonth"}]}>
              </ButtonPicker>
              </div>
              <div className="Chart">
                {chart}
                <Typography
                  component="p"
                  variant="caption"
                  style={{ margin: 20 }}
                >
                  El area sombreada representa valor estimados en base a datos
                  climáticos de años previos.
                </Typography>
              </div>
              {/* <div className="Card">
                <div className="Chart">
                  {todayChart}
                  <Typography
                    component="p"
                    variant="caption"
                    style={{ margin: 20 }}
                  >
                    Datos generados mediante predicciones climáticas
                    proporcionadas por{' '}
                    <Link href="https://openweathermap.org/" target="_blank">
                      OpenWeatherMap
                    </Link>
                    .
                  </Typography>
                </div>
              </div> */}
            {/* </TabContainer> */}
          {/* )} */}
        <Button onClick={()=>this.downFunct(csvString,selectedWeed+'_'+new Date().toLocaleDateString('es-AR'))} variant="contained" color="primary">
          Descargar Datos
        </Button>
        {/* <Button onClick={()=>this.changeRangeChart()} variant="contained" color="primary">
          Gráfico
        </Button> */}
        </div>
        </div>
        <Typography variant="h5" gutterBottom>
          ¿Cómo funciona?
        </Typography>
        <HowDoesItWork />
        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Contacto
        </Typography>
        <Typography variant="body1">
          Para contactarse con nosotros, visite nuestro sitio web{' '}
          <Link
            href="https://bhi-research.github.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            bhi-research.github.io
          </Link>
          .
        </Typography>
        {loading && <LoadingOverlay />}
      </PageContainer>
    )
  }
}

export default App
