import React, { useState } from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import MomentUtils from '@date-io/moment'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


const NewEntry = ({ onNewEntry }) => {
  const [area1, setArea1] = useState('')
  const [area2, setArea2] = useState('')
  const [area3, setArea3] = useState('')

  const [selectedDate, setSelectedDate] = React.useState(new Date())

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const onSaveClick = () => {
    onNewEntry({ selectedDate, area1, area2, area3 })
    setArea1('')
    setArea2('')
    setArea3('')
  }

  const updateAreaValue = updater => e => {
    const value = e.target.value
    updater(value < 0 ? 0 : value)
  }

  return (
    <Grid
      container
      spacing={4}
      style={{ padding: 30 }}
      justify="center"
      alignItems="center"
    >
      <Grid item sm={4} md={2} lg={2} xs={12}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Fecha"
            value={selectedDate}
            onChange={handleDateChange}
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item sm={4} md={2} lg={2} xs={12}>
        <TextField
          id="standard-required-1"
          label="Area 1"
          value={area1}
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Plantas/m2</InputAdornment>
            ),
          }}
          onChange={updateAreaValue(setArea1)}
        />
      </Grid>
      <Grid item sm={4} md={2} lg={2} xs={12}>
        <TextField
          id="standard-required-2"
          label="Area 2"
          value={area2}
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Plantas/m2</InputAdornment>
            ),
          }}
          onChange={updateAreaValue(setArea2)}
        />
      </Grid>
      <Grid item sm={4} md={2} lg={2} xs={12}>
        <TextField
          id="standard-required-3"
          label="Area 3"
          value={area3}
          fullWidth
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Plantas/m2</InputAdornment>
            ),
          }}
          onChange={updateAreaValue(setArea3)}
        />
      </Grid>
      <Grid item sm={3} md={1} lg={1} xs={12}>
        <Button variant="contained" color="primary" onClick={onSaveClick}>
          Guardar
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewEntry
