import React from 'react'

import TextField from '@mui/material/TextField'
import { Link } from '@mui/material'

const LotesField = ({ lotes, setLotes }) => {
  const setLoteHandle = (name, index) => {
    const newLotes = [...lotes]
    newLotes[index] = name
    setLotes(newLotes)
  }

  const addNewLote = e => {
    e.preventDefault()
    const newLotes = [...lotes]
    newLotes.push('')
    setLotes(newLotes)
  }

  return (
    <>
      {lotes.map((lote, index) => (
        <TextField
          key={index}
          value={lote}
          margin="normal"
          fullWidth
          label={`Nombre lote ${index + 1}`}
          onChange={e => setLoteHandle(e.target.value, index)}
        />
      ))}

      <Link underline="hover" onClick={addNewLote} href="#">
        Agregar lote
      </Link>
    </>
  )
}

export default LotesField
