import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Container from '@mui/material/Container'



const modules = [
  {
    title: 'Pronóstico Maleza',
    link: '/pronostico-maleza',
    description:
      'Predice la emergencia de las principales malezas del sudoeste bonaerense.',
    image: require('./pronostico-maleza.jpg'),
  },
  {
    title: 'Cultivo-Maleza',
    link: '/cultivo-maleza',
    description:
      'Predice la interacción entre cultivos y malezas, estimando el impacto de distintas acciones de control.',
    image: require('./trigo.jpeg'),
  },
  {
    title: 'Temperatura y Humedad de Suelos',
    link: '/calculos-suelo',
    description:
      'Predice las condiciones micro-climáticas del suelo sobre las bases de un registro anual ó un pronóstico meteorológico a siete días.',
    image: require('./soil.jpg'),
  },
  {
    title: 'Información Meteorológica de OpenWeatherMap',
    link: '/clima',
    description:
      'Registro del último año y pronóstico a siete días. Información provista por el SIGA en conjunto con OpenWeatherMap',
    image: require('./OpenWeatherMap.png'),
  },
  {
    title: 'Información Meteorológica de MeteoBahia',
    link: '/clima-meteo',
    description:
      'Registro del último año y pronóstico a siete días. Información provista por el SIGA en conjunto con MeteoBahia',
    image: require('./MeteoBahia.png'),
  },
  {
    title: 'Resiembra vicia',
    link: '/resiembra-vicia',
    description: 'Resiembra vicia',
    image: require('./vicia.png'),
  },
]

const Home = () => {
  const classes = {
    heroContent: {
      bgcolor: 'background.paper',
      pt: 8,
      pr: 6
    },
    cardGrid: {
      pt: 8,
      pb: 8,
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
  }
  return (
    <main>
      <div sx={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Manejo Integrado de Malezas
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            Herramientas de soporte a la toma de decisiones
          </Typography>
        </Container>
      </div>
      <Container sx={classes.cardGrid} maxWidth="md">
        <Grid container spacing={8}>
          {modules.map((module, index) => (
            <Grid item key={index} xs={12} sm={6} md={6}>
              <Card sx={classes.card}>
                <CardMedia
                  sx={classes.cardMedia}
                  image={module.image}
                  title="Image title"
                />
                <CardContent sx={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {module.title}
                  </Typography>
                  <Typography>{module.description}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    component={RouterLink}
                    to={module.link}
                  >
                    Visitar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  )
}

export default Home
