import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

import Link from '@mui/material/Link'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'

import SideBarMenu from './SideBarMenu'
import { useAuth } from 'contexts/authContext'
import UserMenu from './UserMenu'
import { useNavigate } from 'react-router-dom'

const classes = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    mr: 2,
  },
  title: {
    flexGrow: 1,
  },
  buttonText: {
    textTransform: 'none',
  }
}

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { isAuthenticated, logout } = useAuth()
  
  const navigate = useNavigate()

  const toggleMenu = () => {
    setMenuOpen(open => !open)
  }

  const handleLogout = () => {
    logout()
    navigate('/users/login')
  }

  return (
    <>
      <HideOnScroll>
        <AppBar>
          <SideBarMenu open={menuOpen} setOpen={setMenuOpen} />
          <Toolbar>
            <IconButton
              edge="start"
              sx={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Link
              href={window.location.pathname === '/' ? '/#' : '/'}
              color="inherit"
              underline="none"
              sx={classes.title}
            >
              <Typography variant="h6" color="inherit">
                Sistema Manejo Integrado de Malezas
              </Typography>
            </Link>
            <UserMenu
              showMenu={isAuthenticated}
              onLogoutClicked={handleLogout}
            />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  )
}

export default Header
