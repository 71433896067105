import { FormControl, FormLabel, IconButton, Modal, Paper } from '@mui/material'
import { Typography, Divider, Button } from '@mui/material'
import { Help } from '@mui/icons-material'
import React, { useState } from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'

const classes = {
  formControl: {
    m: 1,
    minWidth: 200,
  },
  button: {
    mt: 2,
    mb: 2,
    ml: 5,
  },
  title: {
    mt: 1,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  divider: {
    m: 3,
  },
}

const dates = { first: new Date('04-01-2018'), second: new Date('06-30-2018') }

const ControlDates = ({ setDates }) => {
  
  const [firstControl, setFirstControl] = useState(dates.first)
  const [secondControl, setSecondControl] = useState(dates.second)
  const [helpOpen, setHelpOpen] = React.useState(false)
  const handleOpen = () => setHelpOpen(true)
  const handleClose = () => setHelpOpen(false)

  const update = () => {
    setDates({
      firstControl: format(firstControl, 'dd/MM/yyyy'),
      secondControl: format(secondControl, 'dd/MM/yyyy'),
    })
  }

  return (
    <>
      <Typography variant="h5" gutterBottom component="h2" sx={classes.title}>
        Fecha de los controles aplicados
      </Typography>

      <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleOpen}>
        <Help />
      </IconButton>

      <FormControl sx={classes.formControl}>
        <FormLabel id="firstDate">Primera fecha</FormLabel>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <DatePicker
            margin="normal"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            minDate="03-21-2018"
            maxDate="04-11-2018"
            value={firstControl}
            onChange={e => setFirstControl(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl sx={classes.formControl}>
        <FormLabel id="secondDate">Segunda fecha</FormLabel>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <DatePicker
            margin="normal"
            id="date-picker-dialog2"
            format="dd/MM/yyyy"
            value={secondControl}
            onChange={e => setSecondControl(e)}
            minDate="06-20-2018"
            maxDate="07-10-2018"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </LocalizationProvider>
      </FormControl>

      <Button color="primary" variant="contained" sx={classes.button} onClick={update}>
        Aplicar cambios
      </Button>

      <Divider sx={classes.divider} />

      <Modal
        keepMounted
        open={helpOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={classes.modal}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={classes.divider}
          >
            Fechas disponibles
          </Typography>
          <Typography id="modal-modal-description" sx={classes.divider}>
            Se pueden seleccionar fechas que difieran a lo sumo 10 días de las fechas por defecto.
            Esto es, 01/04/2018 para el primer control y 30/6/2018 para el segundo.
          </Typography>
        </Paper>
      </Modal>
    </>
  )
}

export default ControlDates
