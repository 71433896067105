import localidadesFile from './localidades.json'

export default function useProvinciasQuery(provincia) {
  const localidadesByProvincia = localidadesFile

  const localidades = provincia
    ? localidadesByProvincia[provincia.nombre]?.localidades
    : []

  return localidades
}
