import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AUTO_HIDE_DURATION = 6 * 1000

const ErrorNotification = ({ message, onClose }) => (
  <Snackbar
    open={!!message}
    autoHideDuration={AUTO_HIDE_DURATION}
    onClose={onClose}
  >
    <Alert severity="error">{message}</Alert>
  </Snackbar>
)

ErrorNotification.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default ErrorNotification
