import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@mui/material'
import SliderComponent from 'components/ResiembraVicia/Slider'
import React, { useState } from 'react'

const SystemFirstYear = ({ onChange }) => {
  const classes = {
    formControl: {
      minWidth: 120,
    },
  }
  const [system, setSystem] = useState('Siembra Directa')
  const [systemVal, setSystemVal] = useState(0.69)

  const systemSlider = (e, val) => {
    setSystemVal(val)
    onChange(val)
  }

  const changeSystem = e => {
    let val = e.target.value
    let value = val === 'Siembra Directa' ? 0.64 : 0.85
    setSystem(val)
    setSystemVal(value)
    onChange(value)
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={4} sm={4}>
        <FormControl sx={classes.formControl}>
          <InputLabel id="system-selector-label">Sistema</InputLabel>
          <Select
            labelId="system-selector-label"
            id="system-select-helper"
            value={system}
            onChange={changeSystem}
          >
            <MenuItem value="" selected disabled>
              <em>Ninguno</em>
            </MenuItem>
            <MenuItem value={'Siembra Directa'}>Siembra Directa</MenuItem>
            <MenuItem value={'Con Laboreo'}>Con Laboreo</MenuItem>
          </Select>
          <FormHelperText>{`valor: ${systemVal}`}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={8} sm={8}>
        {system === 'Siembra Directa' && (
          <SliderComponent
            min={0.64}
            max={0.73}
            step={0.01}
            defVal={systemVal}
            handleChange={systemSlider}
          />
        )}
        {system === 'Con Laboreo' && (
          <SliderComponent
            min={0.85}
            max={0.93}
            step={0.01}
            handleChange={systemSlider}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default SystemFirstYear
