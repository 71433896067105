import React, { useCallback, useEffect, useState } from 'react'

import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ChartTPLA from '../CultivoMaleza/ChartTPLA'
import ComoFuncionaCultivoMaleza from 'components/CultivoMaleza/ComoFuncionaCultivoMaleza'
import PageContainer from 'components/Shared/PageContainer'
import ChartAcumulado from '../CultivoMaleza/ChartAcumulado'
import ControlDates from './ControlDatesInput'
import axios from 'axios'
import { BASE_URL } from 'utils/server/server'



const CURRENT_YEAR = new Date().getFullYear()

const CultivoMaleza = () => {

  const classes = {
    container: {
      maxWidth: 1000,
      margin: '80px auto 0 auto',
    },
    formControl: {
      m: 1,
      minWidth: 200,
    },
    title: {
      mt: 1,
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 200,
    },
  }

  const [dataTPLATrigo, setDataTPLATrigo] = useState([])
  const [dataAcumulado, setDataAcumulado] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState(null)

  const dayOfYearToDate = dayOfYear => {
    const initialDay = new Date(CURRENT_YEAR, 0)
    return new Date(initialDay.setDate(dayOfYear))
  }

  const getChartsData = useCallback(async () => {
    setError(null)
    setLoading(true)
    try {
      let url =
        dates === null
          ? `${BASE_URL}/cultivo`
          : `${BASE_URL}/cultivo/calculate?firstControl=${dates.firstControl}&secondControl=${dates.secondControl}`

      let { data } = await axios.get(url)

      if (dates !== null) {
        // Necesary parsing, temporal fix.
        data = JSON.parse(data.replaceAll("'", '"'))
      }

      const tplattrigo = data?.map(entry => ({
        day: dayOfYearToDate(entry.id_day),
        TPLATrigo: entry.TPLA_Trigo,
      }))

      const acumulada = data?.map(entry => ({
        day: dayOfYearToDate(entry.id_day),
        plantulas: entry.Acum_plantula,
        vegetativoTemprano: entry.Acum_veg_temp,
        vegetativoAvanzado: entry.Acum_veg_av,
        reproductivoInicial: entry.Acum_rep_inic,
      }))

      setDataTPLATrigo(tplattrigo)
      setDataAcumulado(acumulada)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [dates])

  useEffect(() => {
    getChartsData()
  }, [dates, getChartsData])

  // TODO: Improve this error message.
  if (error) return 'Lo sentimos mucho, hubo un problema al cargar la solicitud'

  return (
    <PageContainer>
      <Typography variant="h5" gutterBottom component="h2" sx={classes.title}>
        Cultivo - Maleza
      </Typography>
      <FormControl sx={classes.formControl}>
        <InputLabel id="cultivo">Cultivo</InputLabel>
        <Select labelId="cultivo" value="trigo">
          <MenuItem value="trigo">Trigo</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={classes.formControl}>
        <InputLabel id="maleza">Maleza</InputLabel>
        <Select labelId="maleza" value="avefa">
          <MenuItem value="avefa">Avena Fatua</MenuItem>
        </Select>
      </FormControl>

      <ControlDates setDates={setDates} />

      {loading ? (
        <div sx={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <ChartAcumulado chartData={dataAcumulado} />
      )}
      {loading ? (
        <div sx={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <ChartTPLA chartData={dataTPLATrigo} />
      )}
      <ComoFuncionaCultivoMaleza />
    </PageContainer>
  )
}

export default CultivoMaleza
