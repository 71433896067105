import axios from 'axios'
import { locations } from './serverWeather'

export const BASE_URL = 'http://pc250.frbb.utn.edu.ar'

export async function runSimulation(location) {
  const locationId = locations[location].id
  try {
    const { data } = await axios.post(`${BASE_URL}/run`, {
      location: locationId,
    })
    data.weather.forEach(element => {
      element.datos = element.datos.reverse()
    })
    return data
  } catch (err) {
    console.log('Error runing simulation:')
    throw new Error('Error running simulation')
  }
}
