import { FormHelperText, Grid, makeStyles, Typography } from '@mui/material'
import SliderComponent from 'components/ResiembraVicia/Slider'
import React, { useState } from 'react'

const options = {
  alta: { min: 0.4, max: 0.56, label: 'alta', color: '#f56342' },
  media: { min: 0.27, max: 0.39, label: 'media', color: '#dea75f' },
  baja: { min: 0, max: 0.26, label: 'baja', color: '#72de5f' },
  max: 0.56,
  min: 0,
  step: 0.01,
}

const SeedLost = ({ onChange, defVal }) => {
  const classes = {
    formControl: {
      m: 1,
      minWidth: 180,
    },
    gridItem: {
      mt: 2,
      mb: 2,
    },
  }
  const [seedLost, setSeedLost] = useState(defVal)

  const changeValue = (e, val) => {
    setSeedLost(val)
    onChange(val)
  }

  const getSeedLostDescription = seedLost => {
    if (seedLost >= options.baja.min && seedLost <= options.baja.max)
      return options.baja.label
    if (seedLost >= options.media.min && seedLost <= options.media.max)
      return options.media.label
    return options.alta.label
  }

  const getSeedLostColor = seedLost => {
    if (seedLost >= options.baja.min && seedLost <= options.baja.max)
      return options.baja.color
    if (seedLost >= options.media.min && seedLost <= options.media.max)
      return options.media.color
    return options.alta.color
  }

  return (
    <Grid container justify="center" sx={classes.gridItem}>
      <Grid item xs={4} sm={4}>
        <Typography>Perdida de semillas:</Typography>
        <FormHelperText
          style={{ color: getSeedLostColor(seedLost) }}
        >{`valor: ${Math.round(seedLost * 100)}% [ ${getSeedLostDescription(
          seedLost,
        )} ]`}</FormHelperText>
      </Grid>
      <Grid item xs={8} sm={8}>
        <SliderComponent
          min={options.min}
          max={options.max}
          step={options.step}
          defVal={seedLost}
          handleChange={changeValue}
        />
      </Grid>
    </Grid>
  )
}

export default SeedLost