import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const EntriesTable = ({ data }) => {
  return (
    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell align="right">Area 1</TableCell>
            <TableCell align="right">Area 2</TableCell>
            <TableCell align="right">Area 3</TableCell>
            <TableCell align="right">Promedio</TableCell>
            <TableCell align="right">Acumulado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.fecha}
              </TableCell>
              <TableCell align="right">{row.area1}</TableCell>
              <TableCell align="right">{row.area2}</TableCell>
              <TableCell align="right">{row.area3}</TableCell>
              <TableCell align="right">{row.promedio}</TableCell>
              <TableCell align="right">{row.acumulado}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EntriesTable
