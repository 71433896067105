import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'

import { defaultOptions } from 'utils/chartsConfig'

class Chart extends PureComponent {
  static propTypes = {
    labels: PropTypes.array.isRequired,
  }

  render() {
    const { labels, temp, tmax, tmin, rain, humidity } = this.props

    if (
      !labels.length ||
      !tmax.length ||
      !tmin.length ||
      !rain.length ||
      !humidity.length ||
      !temp.length
    )
      return null

    const data = {
      labels,
      datasets: [
        {
          type: 'line',
          label: 'Temperatura',
          data: temp,
          backgroundColor: '#2ecc71',
          borderColor: '#2ecc71',
          fill: false,
          display: false,
          lineTension: 0.1,
          hidden: true,
          yAxisID: 'temp',
        },
        {
          type: 'line',
          label: 'Temperatura Máxima',
          data: tmax,
          backgroundColor: '#e84118',
          borderColor: '#e84118',
          fill: false,
          display: false,
          lineTension: 0.1,
          hidden: false,
          yAxisID: 'temp',
        },
        {
          type: 'line',
          label: 'Temperatura Mínima',
          data: tmin,
          backgroundColor: '#00a8ff',
          borderColor: '#00a8ff',
          fill: false,
          display: false,
          lineTension: 0.1,
          hidden: false,
          yAxisID: 'temp',
        },
        {
          type: 'line',
          label: 'Precipitación',
          data: rain,
          backgroundColor: '#7f8fa6',
          borderColor: '#7f8fa6',
          fill: false,
          display: false,
          lineTension: 0.1,
          hidden: false,
          yAxisID: 'rain',
        },
        {
          type: 'line',
          label: 'Humedad',
          data: humidity,
          backgroundColor: '#6abbde',
          borderColor: '#6abbde',
          fill: false,
          display: false,
          lineTension: 0.1,
          hidden: true,
          yAxisID: 'humidity',
        },
      ],
    }

    const options = {
      ...defaultOptions,
      scales: {
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'temp',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Temperatura [°C]',
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'rain',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Precipitación [mm]',
            },
            ticks: {
              beginAtZero: true,
            },
          },
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'humidity',
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Humedad [%]',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <Bar data={data} options={options} />
  }
}

export default Chart
