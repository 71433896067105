import { getEmerge, getSurvival } from './ResiembraModelParameters'

/**
 * Retorna el rendimiento del primer año mediante la
 * fórmula (X/10)*1000/28
 * @param {int} seedYield
 * @returns Rt
 */
export const getRendimiento = seedYield => {
  return ((seedYield / 10) * 1000) / 28
}

/**
 * Calcula las plantas/m^2 de acuerdo al valor
 * de semillas/m^2 de los meses Enero - Febrero (seedPerM2)
 * @param {int} seedPerM2
 * @returns objeto con valores de cohorte de los meses Enero-Febrero,
 *  Febrero-Abril, Abril-Junio, Junio-Septiembre
 */
const getHerbage = seedPerM2 => {
  return {
    cohorte1: seedPerM2 * getSurvival(1),
    cohorte2: seedPerM2 * getSurvival(2),
    cohorte3: seedPerM2 * getSurvival(3),
    cohorte4: seedPerM2 * getSurvival(4),
  }
}

/**
 * Calcula los parámetros del primer año, de acuerdo a las funciones
 * indicadas. Retorna un objeto con los valores necesarios para calcular
 * los años siguientes.
 * @param {Object} obj
 * @returns objeto
 */
const calculateFirstYear = ({ production, seedLost, m, year2SeedLost }) => {
  const performance = getRendimiento(production)
  const janSeedBank = performance * seedLost
  const janFebSeedBank = janSeedBank * m
  const janFebCohorte1 = janFebSeedBank * getEmerge(1)
  const herbage = getHerbage(janFebCohorte1)
  const seedsOnSoilSurfacePerM2 =
    herbage.cohorte2 > 30 ? getRendimiento(year2SeedLost) : 0
  return {
    seedLost: seedsOnSoilSurfacePerM2,
    seedling: janFebCohorte1,
    seedBank: janFebSeedBank,
    fasePastura: herbage.cohorte2,
    faseCereal: herbage.cohorte4,
  }
}

/**
 * Calcula los parámetros de un año determinado, de acuerdo a las funciones
 * indicadas. Retorna un objeto con los valores necesarios para calcular
 * el año siguiente.
 * @param {Object} obj
 * @returns objeto
 */
const calculateYear = ({ seedLost, seedling, seedBank, m, yearSeedLost }) => {
  const janSeedBank = seedLost - seedling + seedBank
  const janFebSeedBank = janSeedBank - seedLost + seedLost * m
  const janFebCohorte1 =
    seedLost * m * getEmerge(1) +
    (janSeedBank - seedLost) * (getEmerge(1) + getEmerge(2))
  const herbage = getHerbage(janFebCohorte1)
  const currentSR = herbage.cohorte2 > 30 ? getRendimiento(yearSeedLost) : 0
  return {
    seedLost: currentSR,
    seedling: janFebCohorte1,
    seedBank: janFebSeedBank,
    fasePastura: herbage.cohorte2,
    faseCereal: herbage.cohorte4,
  }
}

/**
 * Toma un arreglo de calculos por año, y retorna
 * un arreglo de objetos con formato {anio, fasePastura, faseCereal}
 * @param {Array} arr
 * @returns arreglo de objetos
 */
const getResult = arr => {
  let result = []

  for (let i = 0; i < arr.length; i++) {
    result.push({
      anio: i + 2,
      fasePastura: arr[i].fasePastura,
      faseCereal: arr[i].faseCereal,
    })
  }
  return result
}

/**
 * Método de calculo principal, se calculan los años, y se
 * retorna el resultado obtenido
 * @param {Object} object
 * @returns arreglo de objetos {anio, fasePastura, faseCereal}
 */
export const calculateTotal = ({
  production,
  seedLost,
  m,
  year2Secuence,
  year3Secuence,
  year4Secuence,
}) => {
  const year2 = calculateFirstYear({
    production: production,
    seedLost: seedLost,
    m: m,
    year2SeedLost: year2Secuence,
  })

  const year3 = calculateYear({
    seedLost: year2.seedLost,
    seedling: year2.seedling,
    seedBank: year2.seedBank,
    m: m,
    yearSeedLost: year3Secuence,
  })

  const year4 = calculateYear({
    seedLost: year3.seedLost,
    seedling: year3.seedling,
    seedBank: year3.seedBank,
    m: m,
    yearSeedLost: year4Secuence,
  })

  const year5 = calculateYear({
    seedLost: year4.seedLost,
    seedling: year4.seedling,
    seedBank: year4.seedBank,
    m: m,
    yearSeedLost: 0,
  })

  return getResult([year2, year3, year4, year5])
}
