import React, { useEffect, useState } from 'react'
import CalculosSuelo from 'components/CalculosSuelo'
import { useSimulationQuery } from './useSimulationQuery'
import { getLocations } from 'utils/server/serverWeather'

const CalculosSueloPage = () => {
  const simulateMutation = useSimulationQuery()
  const [locationList, setLocationList] = useState(null)

  useEffect(() => {
    getLocations().then(res => {
      // fixing this until we add gral pico to stm2
      res.splice(res.indexOf('gral pico'))
      setLocationList(res)
    })
  }, [])

  return (
    <>
      {locationList && (
        <CalculosSuelo
          locationList={locationList}
          runSimulation={simulateMutation}
        />
      )}
    </>
  )
}

export default CalculosSueloPage
