import axios from 'axios'

export const BASE_URL = 'http://pronostico-malezas.frbb.utn.edu.ar/API'

const weedDataUrl = {
  'Avena Fatua': `${BASE_URL}/avefa2`,
  'Vicia Villosa': `${BASE_URL}/vicia2`,
  'Lolium Multiflorum': `${BASE_URL}/lolium2`,
  'Amaranthus palmeri': `${BASE_URL}/palmeri`,
  'Cenchrus incertus': `${BASE_URL}/roseta`,
  'Conyza bonariensis': `${BASE_URL}/ramanegra`,
}

export async function loginUser(email, password) {
  return await axios.post(`${BASE_URL}/auth/login`, {
    malezas_user: email,
    malezas_pass: password,
  })
}

export async function logoutUser() {
  return await axios.post(`${BASE_URL}/users/logout`)
}

export async function signupUser(name, email, password) {
  return await axios.post(`${BASE_URL}/signup`, {
    malezas_name: name,
    malezas_user: email,
    malezas_pass: password,
  })
}

export async function getGraphData(weed) {
  try {
    const URL = weedDataUrl[weed]
    if (!URL) throw new Error('Weed not found')

    const data = await axios.get(URL)
    
    console.log("From getting data:");
    console.log(URL);
    console.log(data);


    return data
  } catch (error) {
    console.error('[getGraphData] Error', error)
    throw new Error('Error al obtener gráfico')
  }
}

export async function getProfile() {
  const { data } = await axios.get(`${BASE_URL}/users/profile`)
  return data
}

export async function getCampos() {
  const { data } = await axios.get(`${BASE_URL}/users/campos`)
  return data
}

export async function nuevoCampo({
  nombreCampo,
  nombreLotes,
  malezas,
  provincia,
  localidad,
  latitudLongitud,
}) {
  return await axios.post(`${BASE_URL}/users/nuevoCampo`, {
    nombreCampo,
    nombreLotes,
    malezas,
    provincia,
    localidad,
    latitudLongitud,
  })
}

export async function getEntradas(_, idLote) {
  const { data } = await axios.get(`${BASE_URL}/users/entradas`, {
    params: { idLote },
  })

  return data
}

export async function nuevasEntradas(entradas) {
  return await axios.post(`${BASE_URL}/users/nuevasEntradas`, {
    entradas,
  })
}

export async function getMalezas() {
  const { data } = await axios.get(`${BASE_URL}/users/malezas`)
  return data
}
