import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const classes = {
  backdrop: {
    zIndex: 'drawer' + 1,
  },
}

const ScreenLoading = ({ open }) => {

  return (
    <Backdrop sx={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

ScreenLoading.propTypes = {
  open: PropTypes.bool,
}

export default ScreenLoading
