import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

const classes = {
  centered: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const Spinner = () => {
  const classes = useStyles()

  return (
    <div sx={classes.centered}>
      <CircularProgress color="inherit" />
    </div>
  )
}

export default Spinner
