import React from 'react'
import { Bar } from 'react-chartjs-2'

const Chart = ({ data }) => {
  const tableData = {
    labels: data.map(row => row.fecha),
    datasets: [
      {
        label: 'Emergencia Acumulada',
        data: data.map(row => row.acumulado),
        // Changes this dataset to become a line
        type: 'line',
        fill: false,
        backgroundColor: '#3498db',
        borderColor: '#3498db',
        lineTension: 0,
        yAxisID: 'acumulada',
      },
      {
        label: 'Emergencia Diaria',
        data: data.map(row => row.promedio),
        backgroundColor: '#2ecc71',
        yAxisID: 'diaria',
      },
    ],
  }

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || ''

          if (label) {
            label += ': '
          }

          label += Math.round(tooltipItem.yLabel * 100) / 100
          return label
        },
      },
    },
    title: {
      display: false,
      text: '',
      fontSize: 18,
      padding: 20,
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'diaria',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Emergencia Diaria [plantas/m2]',
          },
          labels: {
            show: true,
          },
          ticks: {
            suggestedMin: 0,
          },
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'acumulada',
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Emergencia Acumulada [plantas/m2]',
          },
          ticks: {
            suggestedMin: 0,
          },
        },
      ],
    },
  }

  return <Bar data={tableData} options={options} />
}

export default Chart
