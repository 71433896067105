import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

import blue from '@mui/material/colors/blue'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'

const getColorByName = name => {
  switch (name) {
    case 'Avefa':
      return blue[500]
    case 'Lolium':
      return red[500]
    default:
      return green[500]
  }
}

const Maleza = ({ nombre }) => {
  const color = getColorByName(nombre)

  return (
    <Chip
      label={nombre}
      color="primary"
      style={{ marginRight: 8, marginTop: 8, backgroundColor: color }}
    />
  )
}

Maleza.propTypes = {
  nombre: PropTypes.string.isRequired,
}

export default Maleza
