import React from 'react'
import 'chartjs-plugin-annotation'
import {blue,red} from '@mui/material/colors'

import { defaultLinesStyle } from '../../utils/chartsConfig'
import LineChart from 'components/Shared/LineChart'

const Grafico = ({ data }) => {
  const blueColor = blue[500]
  const redColor = red[500]

  const labels = data.map(entry => entry.dia)
  const temp = data.map(entry => entry.temp)
  const smp = data.map(entry => entry.smp)

  const chartData = {
    labels,
    datasets: [
      {
        ...defaultLinesStyle(redColor),
        label: 'Temperatura (°C)',
        data: temp,
        yAxisID: 'temp',
      },
      {
        ...defaultLinesStyle(blueColor),
        label: 'Potencial agua (kPA)',
        data: smp,
        yAxisID: 'smp',
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'temp',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Temperatura (°C)',
          },
          labels: {
            show: true,
          },
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'smp',
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencial agua (kPA)',
          },
        },
      ],
    },
  }

  return <LineChart data={chartData} showLegends={true} options={options} />
}

export default Grafico
