import React from 'react'

import Autocomplete from '@mui/lab/Autocomplete'
import TextField from '@mui/material/TextField'

import useLocalidadesQuery from './useLocalidadesQuery'

const AutocompleteLocalidades = ({
  localidad,
  setLocalidad,
  selectedProvincia,
  setPosition,
}) => {
  const localidades = useLocalidadesQuery(selectedProvincia)

  return (
    <Autocomplete
      value={localidad}
      options={localidades}
      onChange={(_, newValue) => {
        if (!newValue) return

        const lat = newValue.centroide.lat
        const lng = newValue.centroide.lon
        setPosition(lat, lng)
        setLocalidad(newValue)
      }}
      getOptionLabel={option => option.nombre}
      id="auto-complete-localidades"
      autoComplete
      includeInputInList
      renderInput={params => (
        <TextField
          {...params}
          label="Localidad"
          fullWidth
          type="text"
          margin="normal"
        />
      )}
    />
  )
}

export default AutocompleteLocalidades
