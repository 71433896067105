import React from 'react'
import { Link } from '@mui/material'

const uns = require('./logo-uns.png')
const plapiqui = require('./plapiqui.jpg')
const utn = require('./utn.png')
const cerzos = require('./cerzos.jpeg')
const inta = require('./inta.png')
const bolsa = require('./bolsa.jpg')
const bhi = require('./bhi.png')
const meteo = require('./meteobahia.jpg')



const Footer = () => {
  const classes ={
    footer: {
      bgcolor: 'background.paper',
      p: 4,
      marginTop: '50px',
      position: 'absolute',
      width: '100%',
      left: 0,
    },
    footerContent: {
      maxWidth: 1100,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    image: {
      margin: 20,
      borderRadius: '10%',
    },
  }
  return (
    <footer sx={classes.footer}>
      <div sx={classes.footerContent}>
        <Link href="https://bhi-research.github.io/" target="_blank">
          <img
            src={bhi}
            height={50}
            sx={classes.image}
            alt="logo de bhi research"
          />
        </Link>
        <Link href="https://www.frbb.utn.edu.ar/frbb/index.php" target="_blank">
          <img
            src={utn}
            height={50}
            sx={classes.image}
            alt="logo de utn bahia blanca"
          />
        </Link>
        <Link href="https://www.uns.edu.ar/" target="_blank">
          <img
            src={uns}
            height={50}
            sx={classes.image}
            alt="logo de uns bahia blanca"
          />
        </Link>
        <Link href="https://cerzos.conicet.gov.ar/" target="_blank">
          <img
            src={cerzos}
            height={50}
            sx={classes.image}
            alt="logo de cerzos conicet"
          />
        </Link>
        <Link href="https://www.plapiqui.conicet.gov.ar/" target="_blank">
          <img
            src={plapiqui}
            height={50}
            sx={classes.image}
            alt="logo de palpiqui conicet"
          />
        </Link>
      </div>

      <div sx={classes.footerContent}>
        <Link href="https://www.bcp.org.ar/" target="_blank">
          <img
            src={bolsa}
            height={50}
            sx={classes.image}
            alt="logo de bolsa de cereales y productos"
          />
        </Link>
        <Link
          href="https://www.meteobahia.com.ar/"
          style={{ textDecoration: 'none' }}
          target="_blank"
        >
          <img
            src={meteo}
            height={50}
            sx={classes.image}
            alt="logo de inta argentina"
          />
        </Link>
        <Link href="https://www.argentina.gob.ar/inta" target="_blank">
          <img
            src={inta}
            height={50}
            sx={classes.image}
            alt="logo de inta argentina"
          />
        </Link>
      </div>
    </footer>
  )
}

export default Footer