import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@mui/material'

const classes = {
  container: {
    marginTop: 2,
    marginBottom: 20,
  },
}

const PageContainer = ({ children }) => {
  return <Container maxWidth={'lg'} sx={classes.container}>{children}</Container>
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageContainer
