import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'

import image from './tablet-in-wheat-field.png'
import { Link } from 'react-router-dom'
import { useAuth } from 'contexts/authContext'
import { useNavigate } from 'react-router-dom'
import ErrorNotification from 'components/Shared/ErrorNotification'
import ScreenLoading from 'components/Shared/ScreenLoading'

const Login = () => {
  
  const classes = {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        (theme) => theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      mt: 8,
      ml: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      mt: 1,
      backgroundColor: secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      mt: 1,
    },
    submit: {
      mt: 3,
      ml: 2
    }
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useAuth()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)

  const loginHandler = async e => {
    e.preventDefault()
    if (!email || !password) return

    setShowSpinner(true)

    try {
      await login(email, password)
      navigate('/users/panel')
    } catch (e) {
      console.error(e)
      const errorMessage = e.response ? e.response.data.message : e.message
      setErrorMessage(errorMessage)
    } finally {
      setShowSpinner(false)
    }
  }

  return (
    <>
      <Grid container component="main" sx={classes.root}>
        <Grid item xs={false} sm={4} md={7} sx={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div sx={classes.paper}>
            <Avatar sx={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar sesión
            </Typography>
            <form sx={classes.form} noValidate onSubmit={loginHandler}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="malezas_user"
                autoFocus
                onChange={e => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="malezas_pass"
                label="Contraseña"
                type="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={classes.submit}
                disabled={!email || !password}
              >
                Iniciar sesión
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link variant="body2" to="/users/signup">
                    Crear nueva cuenta
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <ErrorNotification
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <ScreenLoading open={showSpinner} />
    </>
  )
}

export default Login
