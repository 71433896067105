import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { loginUser, logoutUser } from 'utils/server/server'
import { storeToken, loadToken } from 'utils/storage'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => loadToken())
  const [tokenSet, setTokenSet] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(!!token)

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      setIsAuthenticated(false)
      delete axios.defaults.headers.common['Authorization']
    }

    setTokenSet(true)
  }, [token])

  if (token === undefined || !tokenSet) return 'loading...'

  const login = async (email, password) => {
    const response = await loginUser(email, password)
    const { token } = response.data
    storeToken(token)
    setToken(token)
  }

  const logout = () => {
    storeToken('')
    setToken('')
    logoutUser()
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
