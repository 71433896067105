import { Box, Card, CardContent } from '@mui/material'
import { FormHelperText, Grid, makeStyles, Typography } from '@mui/material'
import PageContainer from 'components/Shared/PageContainer'
import SliderComponent from './Slider'
import React, { useCallback, useEffect, useState } from 'react'
import { calculateTotal } from 'utils/ResiembraVicia/ResiembraModel'
import SeedLost from './SeedLost'
import SystemFirstYear from './SystemFirstYear'
import YearSecuence from './YearSecuence'
import ResiembraViciaChart from './ResiembraViciaChart'
import ResiembraText from './ResiembraText'

const ResiembraVicia = () => {
  const classes = {
    card: {
      mt: 5,
    },
    gridItem: {
      mt: 1,
      mb: 1,
    },
    lastItem: {
      mt: 1,
      mb: 5,
    },
    chart: {
      mt: 5,
      mb: 2,
    },
  }
  const [seedPerformance, setSeedPerformance] = useState(500)
  const [seedLost, setSeedLost] = useState(0.26)
  const [system, setSystem] = useState(0.69)
  const [year2, setYear2] = useState(15)
  const [year3, setYear3] = useState(140)
  const [year4, setYear4] = useState(0)
  const [chartData, setChartData] = useState(undefined)

  const seedYieldSlider = (e, val) => setSeedPerformance(val)

  const doCalculation = useCallback(async () => {
    let obj = {
      production: seedPerformance,
      seedLost: seedLost,
      m: system,
      year2Secuence: year2,
      year3Secuence: year3,
      year4Secuence: year4,
    }

    setChartData(calculateTotal(obj))
  }, [setChartData, seedPerformance, seedLost, system, year2, year3, year4])

  useEffect(() => {
    doCalculation()
  }, [doCalculation])

  return (
    <PageContainer>
      <Typography variant="h5" gutterBottom component="h2">
        Resiembra Vicia
      </Typography>
      <Box component={Card} boxShadow={3} sx={classes.card}>
        {chartData !== undefined && (
          <Box component={CardContent} sx={classes.chart}>
            <Grid container justify="center">
              <Grid item xs={10} sm={10} md={10} sx={classes.gridItem}>
                <Typography variant="h6" gutterBottom component="h2">
                  Predicción generada:
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10}>
                <ResiembraViciaChart data={chartData} />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box component={CardContent}>
          <Grid
            container
            direction="row"
            justify="center"
            sx={classes.gridItem}
          >
            <Grid item xs={8} sm={8} md={8} sx={classes.gridItem}>
              <Grid container direction="row" justify="flex-start">
                <Typography variant="h5">Año 1</Typography>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.gridItem}>
                    <Grid item xs={4} sm={4}>
                      <Typography>Rendimiento de semillas:</Typography>
                      <FormHelperText>{`valor: ${seedPerformance}`}</FormHelperText>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <SliderComponent
                        min={100}
                        max={1200}
                        defVal={seedPerformance}
                        handleChange={seedYieldSlider}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.gridItem}>
                    <SeedLost onChange={setSeedLost} defVal={seedLost} />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.gridItem}>
                    <SystemFirstYear onChange={setSystem} defVal={system} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} sm={8} md={8} sx={classes.gridItem}>
              <Grid container direction="row" justify="flex-start">
                <Typography variant="h5">Año 2</Typography>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.gridItem}>
                    <YearSecuence handleChange={setYear2} year={2} defVal={3} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} sm={8} md={8} sx={classes.gridItem}>
              <Grid container direction="row" justify="flex-start">
                <Typography variant="h5">Año 3</Typography>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.gridItem}>
                    <YearSecuence handleChange={setYear3} year={3} defVal={4} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} sm={8} md={8} sx={classes.gridItem}>
              <Grid container direction="row" justify="flex-start">
                <Typography variant="h5">Año 4</Typography>

                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={1} sx={classes.lastItem}>
                    <YearSecuence handleChange={setYear4} year={4} defVal={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ResiembraText />
    </PageContainer>
  )
}

export default ResiembraVicia
