import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import PageContainer from 'components/Shared/PageContainer'

import LotesField from './LotesField'
import AutocompleteProvincias from './AutocompleteProvincias'
import AutocompleteLocalidades from './AutocompleteLocalidades'
import Mapa from './Mapa'
import SelectMalezas from './SelectMalezas'
import ScreenLoading from 'components/Shared/ScreenLoading'
import ErrorNotification from 'components/Shared/ErrorNotification'

const classes = {
  page: {
    marginTop: 15,
    flexGrow: 1,
    minHeight: 400,
  },
  paper: {
    mt: 8,
    mr: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    mt: 3,
    mb: 2
  },
}

const NuevoCampo = ({
  agregarNuevoCampo,
  listadoMalezas,
  creandoNuevoCampo,
  errorCreandoCampo,
  resetError,
}) => {
  
  const navigate = useNavigate()
  const [nombreCampo, setNombreCampo] = useState('')
  const [lotes, setLotes] = useState([''])
  const [position, setPosition] = useState([-37.8032, -63.0423])
  const [provincia, setProvincia] = useState(null)
  const [localidad, setLocalidad] = useState(null)
  const [malezas, setMalezas] = useState([])

  const confirmaAgregarNuevoCampo = async e => {
    e.preventDefault()
    const nombreLocalidad = localidad.nombre
    const nombreProvincia = provincia.nombre
    const latitudLongitud = position.join(', ')

    await agregarNuevoCampo(
      nombreCampo,
      lotes,
      malezas,
      nombreProvincia,
      nombreLocalidad,
      latitudLongitud,
    )

    navigate('/users/panel')
  }

  const setPositionHandle = (latitude, longitude) => {
    const roundedLatitude =
      Math.round((latitude + Number.EPSILON) * 10000) / 10000
    const roundedLongitude =
      Math.round((longitude + Number.EPSILON) * 10000) / 10000

    setPosition([roundedLatitude, roundedLongitude])
  }

  const allCompleted =
    nombreCampo && lotes[0] && localidad && provincia && malezas[0]

  return (
    <PageContainer>
      <Paper sx={classes.page}>
        <Grid container component="main" sx={classes.root2} spacing={2}>
          <Grid item xs={12} sm={8} md={6}>
            <div sx={classes.paper}>
              <form sx={classes.form} noValidate>
                <TextField
                  value={nombreCampo}
                  margin="normal"
                  fullWidth
                  label="Nombre del campo"
                  autoFocus
                  onChange={e => setNombreCampo(e.target.value)}
                />
                <LotesField lotes={lotes} setLotes={setLotes} />
                <AutocompleteProvincias
                  provincia={provincia}
                  setProvincia={setProvincia}
                />
                <AutocompleteLocalidades
                  localidad={localidad}
                  setLocalidad={setLocalidad}
                  selectedProvincia={provincia}
                  setPosition={setPositionHandle}
                />

                {`Latitud: ${position[0]}, Longitud: ${position[1]}`}
                <SelectMalezas
                  listadoMalezas={listadoMalezas}
                  malezas={malezas}
                  setMalezas={setMalezas}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={classes.submit}
                  onClick={confirmaAgregarNuevoCampo}
                  disabled={!allCompleted}
                >
                  Agregar sitio
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={classes.submit}
                  component={RouterLink}
                  to="/users/panel"
                >
                  Volver
                </Button>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={6} style={{ position: 'relative' }}>
            <Mapa position={position} setPosition={setPositionHandle} />
          </Grid>
        </Grid>
      </Paper>
      <ErrorNotification message={errorCreandoCampo} onClose={resetError} />
      <ScreenLoading open={creandoNuevoCampo} />
    </PageContainer>
  )
}

NuevoCampo.propTypes = {
  agregarNuevoCampo: PropTypes.func.isRequired,
  listadoMalezas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  creandoNuevoCampo: PropTypes.bool,
  errorCreandoCampo: PropTypes.string,
  resetError: PropTypes.func.isRequired,
}

export default NuevoCampo
