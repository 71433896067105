import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

const SelectorPeriodo = ({ changePeriod }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(1)

  const handleChange = (_, newValue) => {
    setSelectedPeriod(newValue)
    changePeriod(newValue)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Elegir período
      </Typography>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedPeriod}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Ultimo año" />
          <Tab label="Pronóstico 7 días" />
        </Tabs>
      </AppBar>
    </>
  )
}

export default SelectorPeriodo
