import { useQuery } from 'react-query'
import { getProfile } from 'utils/server/server'

const useProfileQuery = () => {
  const { status, data, err } = useQuery('get-profile', getProfile)

  const loading = status === 'loading'
  const error = status === 'error' ? err : null

  return {
    data,
    loading,
    error,
  }
}

export default useProfileQuery
