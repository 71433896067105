import { useQuery } from 'react-query'
import { getEntradas } from 'utils/server/server'

export const useEntradasQuery = idLote => {
  const { isLoading, data, error } = useQuery(
    ['get-entradas', idLote],
    getEntradas,
  )

  return {
    entradas: data?.entradas,
    loading: isLoading,
    error,
  }
}
