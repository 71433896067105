import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import CssBaseline from '@mui/material/CssBaseline'

import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import {blue,grey} from '@mui/material/colors'

import Header from 'components/Header'
import Footer from 'components/Footer/index'
import CultivoMaleza from 'pages/CultivoMaleza'
import CultivoMaleza2 from 'pages/CultivoMaleza2'
import PronosticoMaleza from 'pages/PronosticoMalezas'
import Home from 'pages/Home'
import Login from 'pages/Login'
import Signup from 'pages/Signup'
import PanelUsuario from 'pages/PanelUsuario'
import Profile from 'pages/Profile'
import NuevoCampo from 'pages/NuevoCampo'
import CargaDatos from 'pages/CargaDatos'
import CalculosSuelo from 'pages/CalculosSuelo'
import WeatherPage from 'pages/Weather'
import WeatherMeteoPage from 'pages/WeatherMeteo'
import AboutUs from 'pages/AboutUs'
import ResiembraVicia from 'pages/ResiembraVicia'

import ScrollToTop from 'components/App/ScrollToTop'
import PrivateRoute from 'components/App/PrivateRoute'

import { AuthProvider } from 'contexts/authContext'

const theme = createTheme({
  palette: {
    primary: blue,
    background: {
      default: grey['50'],
    },
  },
})

// const customStyles = theme => ({
//   graph: {
//     backgroundColor: theme.palette.background.paper,
//     marginBottom: 20,
//   },
//   app: {
//     margin: '0 20px',
//   },
// })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CssBaseline />
          <Router>
            <ScrollToTop />
            <div className="AppContaier">
              <Header />
              <Routes>
                <Route path="/cultivo-maleza" element={<CultivoMaleza />}/>
                <Route path="/cultivo-maleza2" element={<CultivoMaleza2 />}/>
                <Route path="/pronostico-maleza" element={<PronosticoMaleza />}/>
                <Route path="/calculos-suelo" element={<CalculosSuelo />}/>
                <Route path="/clima" element={<WeatherPage />}/>
                <Route path="/clima-meteo" element={<WeatherMeteoPage />}/>
                <Route path="/about" element={<AboutUs />}/>
                <Route path="/users/login" element={<Login />}/>
                <Route path="/users/signup" element={<Signup />}/>
                <Route path="/users/profile" element={<PrivateRoute><Profile /></PrivateRoute>}/>
                <Route path="/users/panel" element={<PrivateRoute><PanelUsuario /></PrivateRoute>}/>
                <Route path="/users/nuevo-sitio" element={<PrivateRoute><NuevoCampo /></PrivateRoute>}/>
                <Route path="/users/carga-datos/:idLote" element={<PrivateRoute><CargaDatos /></PrivateRoute>}/>
                <Route path="/resiembra-vicia" element={<ResiembraVicia />}/>
                <Route path="/" element={<Home />}/>
              </Routes>
              <Footer />
            </div>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
