import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  FormControl,
  Typography,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from '@mui/material'
import PageContainer from 'components/Shared/PageContainer'
import TablaTemperaturaHumedad from './TablaTemperaturaHumedad'
import Grafico from './Grafico'
import SelectorGraficos from './SelectorGraficos'
import Spinner from 'components/Shared/Spinner'
import LoadingOverlay from 'components/CalculosSuelo/LoadingOverlay'
import SelectorPeriodo from './SelectorPeriodo'
import DownloableButton from 'components/Shared/DownloableButton'

const twoDecimals = n => Number.parseFloat(n).toFixed(2)

const CalculoSuelo = ({ locationList, runSimulation }) => {
  const classes = {
    card: {
      marginBottom: 12,
    },
    wrapperGrafico: {
      maxWidth: 800,
      margin: 'auto',
    },
    containerGrafico: {
      margin: 'auto',
    },
    tabContent: {
      padding: 20,
    },
  }
  const initialSelectedLocation = locationList[0].id
  const [selectedLocation, setSelectedLocation] = useState(
    initialSelectedLocation,
  )
  const [loadingData, setLoadingData] = useState(true)
  const [weatherData, setWeatherData] = useState(null)
  const [forecastData, setForecastData] = useState(null)
  const [datos1cm, setDatos1cm] = useState([])
  const [datos2cm, setDatos2cm] = useState([])
  const [datos4cm, setDatos4cm] = useState([])
  const [datos10cm, setDatos10cm] = useState([])
  const [error, setError] = useState(null)
  const [selectedDepth, setSelectedDepth] = useState(0)

  const simulate = useCallback(
    async location => {
      try {
        const data = await runSimulation(location)
        setLoadingData(true)
        const wdata = getFormattedData(data.weather)
        const fdata = getFormattedData(data.forecast)
        setWeatherData(wdata)
        setForecastData(fdata)
        setDatos1cm(fdata.find(depth => depth.profundidad === '1cm').datos)
        setDatos2cm(fdata.find(depth => depth.profundidad === '2cm').datos)
        setDatos4cm(fdata.find(depth => depth.profundidad === '4cm').datos)
        setDatos10cm(fdata.find(depth => depth.profundidad === '10cm').datos)
      } catch (e) {
        console.log('Error getting data.')
        setError(e)
      } finally {
        setLoadingData(false)
      }
    },
    [runSimulation],
  )

  const getFormattedData = data => {
    return data.map(d => ({
      ...d,
      datos: d.datos.map(entry => ({
        ...entry,
        smp: twoDecimals(entry.smp),
        temp: twoDecimals(entry.temp),
      })),
    }))
  }

  const handlePeriodChange = period =>
    refreshData(period === 0 ? weatherData : forecastData)

  const refreshData = data => {
    if (error) return
    setDatos1cm(data.find(depth => depth.profundidad === '1cm').datos)
    setDatos2cm(data.find(depth => depth.profundidad === '2cm').datos)
    setDatos4cm(data.find(depth => depth.profundidad === '4cm').datos)
    setDatos10cm(data.find(depth => depth.profundidad === '10cm').datos)
  }

  useEffect(() => {
    simulate(initialSelectedLocation)
  }, [simulate, initialSelectedLocation])

  const onSelectedLocationChange = async e => {
    setLoadingData(true)
    setSelectedLocation(e.target.value)
    simulate(e.target.value)
  }

  if (loadingData) return <Spinner />

  return (
    <PageContainer>
      <Typography variant="h5" gutterBottom component="h2">
        Temperatura y Humedad de Suelos
      </Typography>

      <Card sx={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Datos de entrada
          </Typography>
          <FormControl>
            <InputLabel id="ubicacion">Ubicación</InputLabel>
            <Select
              labelId="ubicacion"
              value={selectedLocation}
              onChange={onSelectedLocationChange}
            >
              {locationList.map(location => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>
      <Card sx={classes.card}>
        <CardContent>
          <SelectorPeriodo changePeriod={e => handlePeriodChange(e)} />
          <SelectorGraficos changeDepth={e => setSelectedDepth(e)} />
          <div sx={classes.tabContent}>
            <div sx={classes.wrapperGrafico}>
              {!error && selectedDepth === 0 && (
                <>
                  <Grafico data={datos1cm} />
                  <TablaTemperaturaHumedad data={datos1cm} />
                </>
              )}
              {!error && selectedDepth === 1 && (
                <>
                  <Grafico data={datos2cm} />
                  <TablaTemperaturaHumedad data={datos2cm} />
                </>
              )}
              {!error && selectedDepth === 2 && (
                <>
                  <Grafico data={datos4cm} />
                  <TablaTemperaturaHumedad data={datos4cm} />
                </>
              )}
              {!error && selectedDepth === 3 && (
                <>
                  <Grafico data={datos10cm} />
                  <TablaTemperaturaHumedad data={datos10cm} />
                </>
              )}
              {error != null && <h1>Error al obtener los datos</h1>}
            </div>
          </div>
        </CardContent>
      </Card>

      <Grid container justify="center" style={{ marginTop: 20 }}>
        <Grid item xs={3} sm={3}>
          <DownloableButton
            legend={'Descargar historicos'}
            data={weatherData}
            isJson={false}
            filename={'calculos_suelo_historico'}
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <DownloableButton
            legend={'Descargar pronóstico'}
            data={forecastData}
            isJson={false}
            filename={'calculos_suelo_pronostico'}
          />
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
        ¿Cómo funciona?
      </Typography>
      <Typography variant="body1">
        El conocimiento de las condiciones micro climáticas del suelo
        (temperatura y humedad) es de gran importancia para el desarrollo de
        modelos certeros de germinación y emergencia de malezas. Este módulo
        proporciona dicha información sobre la base de un pronóstico
        meteorológico a 5 días. Las estimaciones están basadas en los
        desarrollos y software descriptos en [1].
      </Typography>
      <Typography variant="h6" gutterBottom>
        Referencias
      </Typography>
      <Typography variant="body1">
        [1] Kurt Spokas and Frank Forcella; Software Tools for Weed Seed
        Germination Modeling; Weed Science 2009 57:216–227.
        https://www.cambridge.org/core/journals/weed-science/article/abs/software-tools-for-weed-seed-germination-modeling/15315ACB4DA2D1B058AE45FA894D4C54
      </Typography>
      {loadingData ? <LoadingOverlay /> : null}
    </PageContainer>
  )
}

export default CalculoSuelo
