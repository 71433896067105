import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const ButtonPicker = (props) => {
    return <FormControlLabel 
        label={props.label}
        labelPlacement='start'
        
        sx={{
            '& .MuiFormControlLabel-label':{marginRight:1},
            marginTop:2
        }}
        control={
            <ToggleButtonGroup
                value={props.value}
                exclusive={true}
                size='small'
                onChange={props.changeHandler}
                aria-label={props.label}
            >
            {props.values.map((element)=>
                <ToggleButton value={element.value} aria-label={element.label}>
                    {element.label}
                </ToggleButton>
            )}
            </ToggleButtonGroup>
        }
    />;
}

export default ButtonPicker