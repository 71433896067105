import React from 'react'
import PanelUsuario from 'components/PanelUsuario'
import Spinner from 'components/Shared/Spinner'

import { useCamposQuery } from './useCamposQuery'
import { useMalezasQuery } from '../Shared/getMalezas'

const PaginaPanelUsuario = () => {
  const { campos, loading, error } = useCamposQuery()
  const { malezas } = useMalezasQuery()

  if (loading) return <Spinner />
  if (error) return `Error cargando pagina: ${error}`

  return <PanelUsuario campos={campos} listadoMalezas={malezas} />
}

export default PaginaPanelUsuario
