import React from 'react'
import 'chartjs-plugin-annotation'
import blue from '@mui/material/colors/blue'

import { formatChartDate, defaultLinesStyle } from '../../utils/chartsConfig'

import LineChart from '../Shared/LineChart'
import { getFiveDaysFromNowDate } from '../../utils/datetime'

const ChartTPLA = ({ chartData }) => {
  const blueColor = blue[500]

  const labels = chartData.map(entry => formatChartDate(entry.day))
  const values = chartData.map(entry => entry.TPLATrigo)

  const data = {
    labels,
    datasets: [
      {
        ...defaultLinesStyle(blueColor),
        label: 'TPLA Trigo',
        data: values,
      },
    ],
  }

  const shadowBoxStartDay = getFiveDaysFromNowDate()
  const formattedShadowBoxStartDay = formatChartDate(shadowBoxStartDay)

  return (
    <LineChart
      data={data}
      title="TPLA Trigo vs Tiempo"
      showLegends={false}
      shadowBoxStartDay={formattedShadowBoxStartDay}
    />
  )
}

export default ChartTPLA
