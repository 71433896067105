import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import { useAuth } from 'contexts/authContext'

const PrivateRoute = ({ children, ...rest }) => {
  let { isAuthenticated } = useAuth()

  // return (
  //   <Route
  //     {...rest}
  //     render={() =>
  //       isAuthenticated ? children : <Navigate to="/users/login" />
  //     }
  //   />
  // )

  if(!isAuthenticated){
    return  <Navigate to="/users/login" />;
  }

  return children;
}

export default PrivateRoute
