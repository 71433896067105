import React from 'react'

import Autocomplete from '@mui/lab/Autocomplete'
import TextField from '@mui/material/TextField'

import useProvinciasQuery from './useProvinciasQuery'

const AutocompleteProvincias = ({ provincia, setProvincia }) => {
  const provincias = useProvinciasQuery()

  return (
    <Autocomplete
      value={provincia}
      onChange={(_, newValue) => {
        setProvincia(newValue)
      }}
      options={provincias}
      getOptionLabel={option => option.nombre}
      id="auto-complete"
      autoComplete
      includeInputInList
      renderInput={params => (
        <TextField
          {...params}
          label="Provincia"
          fullWidth
          type="text"
          margin="normal"
        />
      )}
    />
  )
}

export default AutocompleteProvincias
