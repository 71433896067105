import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const InputData = ({ weed, location }) => {
  return (
    <Paper>
      <Table>
        <TableBody>
          <TableRow key="ubicacion">
            <TableCell component="th" scope="row">
              <b>Maleza</b>
            </TableCell>
            <TableCell align="right">{weed}</TableCell>
          </TableRow>
          <TableRow key="value">
            <TableCell component="th" scope="row">
              <b>Ubicación de la predicción</b>
            </TableCell>
            <TableCell align="right">{location}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default InputData
