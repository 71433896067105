import React from 'react'
import { Slider, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

function ValueLabelComponent(props) {
  const { children, open, value } = props

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
}

const SliderComponent = ({ defVal, min, max, handleChange, step }) => {
  return (
    <Slider
      ValueLabelComponent={ValueLabelComponent}
      aria-label="Thumb label"
      defaultValue={defVal ?? min}
      min={min}
      max={max}
      onChange={handleChange}
      step={step ?? 1}
    />
  )
}

export default SliderComponent
