import React from 'react'
import NuevoCampo from 'components/NuevoCampo'
import { useAgregarNuevoCampo } from './useAgregarNuevoCampo'
import { useMalezasQuery } from '../Shared/getMalezas'

const PaginaNuevoCampo = () => {
  const { agregarNuevoCampo, isLoading, error, reset } = useAgregarNuevoCampo()
  const { malezas } = useMalezasQuery()

  return (
    <NuevoCampo
      agregarNuevoCampo={agregarNuevoCampo}
      listadoMalezas={malezas}
      creandoNuevoCampo={isLoading}
      errorCreandoCampo={error}
      resetError={reset}
    />
  )
}

export default PaginaNuevoCampo
