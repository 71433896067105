import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

const SelectorGraficos = ({ changeDepth }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
    changeDepth(newValue)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Predicciones generadas para multiples profundidades
      </Typography>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="1cm" />
          <Tab label="2cm" />
          <Tab label="4cm" />
          <Tab label="10cm" />
        </Tabs>
      </AppBar>
    </>
  )
}

export default SelectorGraficos
